import { useComposableApi } from '@composable-api-config'

export default defineNuxtPlugin({
    name: 'setup',
    parallel: true,
    dependsOn: ['properties'],
    setup: (nuxtApp) => {
        const runtimeConfig = useRuntimeConfig()
        const api = useComposableApi()
        const { notifyError } = useNotifications()
        const authStore = useAuthStore()

        const { currency } = useProperties()

        // TODO: make values reactive
        // TODO: fix usage in '/simploshop-fe/shared/modules/simploshop-api/src/runtime/composables/localization.ts'
        // @ts-ignore
        api.setHeader('Language-Preference', nuxtApp.$i18n.locale)
        api.setHeader('Currency-Preference', currency.value as any)

        api.setAuthorizationInterceptor((controller, data) => {
            if (import.meta.server) {
                controller.setRequestCookie(
                    runtimeConfig.public.authTokenCookieName,
                    data?.event?.context.newAuthToken,
                    { fallbackToForwarding: true }
                )
            } else if (!data.willCookiesBeSentFromClient) {
                controller.setRequestHeader('Authorization', `Bearer ${controller.getClientCookie(runtimeConfig.public.authTokenCookieName)}`)
            }
        })

        api.setRequestHeadersInterceptor((controller) => {
            // forward the client headers to the backend during SSR on the app side
            if (!import.meta.server) return
            controller.setRequestHeader('user-agent', null, { fallbackToForwarding: true })
            controller.setRequestHeader('x-forwarded-for', null, { fallbackToForwarding: true })
            controller.setRequestHeader('if-modified-since', null, { fallbackToForwarding: true })
            controller.setRequestHeader('if-none-match', null, { fallbackToForwarding: true })
        })

        api.setRefreshSessionInterceptor(async (data) => {
            try {
                await refreshAuthSession(data.event)
            } catch (e) {
                authStore.removeCustomer()
                notifyError(
                    // @ts-ignore
                    nuxtApp.$i18n.t('_core_theme.errors.session_refresh_failed')
                )
                // re-throw the error to be able to handle it elsewhere
                throw e
            }
        })
    },
})
