import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [CustomerSessionModel.ATTR_ID]: number
    [CustomerSessionModel.ATTR_CUSTOMER_ID]: number
    [CustomerSessionModel.ATTR_CLIENT_IP]: string
    [CustomerSessionModel.ATTR_USER_AGENT]: string
    [CustomerSessionModel.ATTR_ACCESS_TOKEN]: string
    [CustomerSessionModel.ATTR_REFRESH_TOKEN]: string
    [CustomerSessionModel.ATTR_ACCESS_EXPIRATION_AT]: string
    [CustomerSessionModel.ATTR_SESSION_EXPIRATION]: string
}

export class CustomerSessionModel extends ApiModel<Attributes> {
    static readonly ATTR_ID = 'id'
    static readonly ATTR_CUSTOMER_ID = 'customer_id'
    static readonly ATTR_CLIENT_IP = 'client_ip'
    static readonly ATTR_USER_AGENT = 'user_agent'

    static readonly ATTR_ACCESS_TOKEN = 'access_token'
    static readonly ATTR_REFRESH_TOKEN = 'refresh_token'
    static readonly ATTR_ACCESS_EXPIRATION_AT = 'access_expiration_at'
    static readonly ATTR_SESSION_EXPIRATION = 'session_expiration_at'

    get id() {
        return this._getAttribute(CustomerSessionModel.ATTR_ID)
    }

    get customerId() {
        return this._getAttribute(CustomerSessionModel.ATTR_CUSTOMER_ID)
    }

    get clientIp() {
        return this._getAttribute(CustomerSessionModel.ATTR_CLIENT_IP)
    }

    get userAgent() {
        return this._getAttribute(CustomerSessionModel.ATTR_USER_AGENT)
    }

    get accessToken() {
        return this._getAttribute(CustomerSessionModel.ATTR_ACCESS_TOKEN)
    }

    get refreshToken() {
        return this._getAttribute(CustomerSessionModel.ATTR_REFRESH_TOKEN)
    }

    get accessExpirationAt() {
        return this._getAttribute(CustomerSessionModel.ATTR_ACCESS_EXPIRATION_AT)
    }

    get sessionExpirationAt() {
        return this._getAttribute(CustomerSessionModel.ATTR_SESSION_EXPIRATION)
    }
}
