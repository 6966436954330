import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [ShopInfoModel.ATTR_ID]: number
    [ShopInfoModel.ATTR_NAME]: string
    [ShopInfoModel.ATTR_THEME]: string
    [ShopInfoModel.ATTR_GUID]: string
}

interface Embeds {
    [ShopInfoModel.EMBED_HOSTNAMES]: {
        shop_hostname: {
            hostname: string
            currency_id: string | null
            language_id: string | null
            country_id: string | null
        }
        language_preference: {
            // TODO: check types
            preferred: string
            fallback: string | null
            language_ids_by_priority: string[]
        }
    }[]
    [ShopInfoModel.EMBED_ROUTES]: Record<string, {
        // these are Translation objects because this model is fetched from the API when the Language-Preference header is
        // not set yet
        slug: Record<string, string | null> | null
        queries: Record<string, Record<string, string | null> | null> | null
    }>
}

export class ShopInfoModel extends ApiModel<Attributes, Embeds> {
    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_THEME = 'theme'
    static readonly ATTR_GUID = 'guid'

    static readonly EMBED_HOSTNAMES = 'hostnames'
    static readonly EMBED_ROUTES = 'routes'

    get id() {
        return this._getAttribute(ShopInfoModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(ShopInfoModel.ATTR_NAME)
    }

    get theme() {
        return this._getAttribute(ShopInfoModel.ATTR_THEME)
    }

    get guid() {
        return this._getAttribute(ShopInfoModel.ATTR_GUID)
    }

    get hostnames() {
        return this._getEmbed(ShopInfoModel.EMBED_HOSTNAMES)
    }

    get routes() {
        return this._getEmbed(ShopInfoModel.EMBED_ROUTES)
    }
}
