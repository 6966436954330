import { ApiModel } from '@composable-api/api.model'
import type { ApiDateTime, Length, Monetary, Translation, Volume, Weight } from '../types/general-data'

interface Attributes {
    [OrderItemModel.ATTR_ID]: number
    [OrderItemModel.ATTR_NAME]: Translation
    [OrderItemModel.ATTR_VARIATION_NAME]: Translation | null
    [OrderItemModel.ATTR_BRAND_ID]: number | null
    [OrderItemModel.ATTR_PRICE_LIST_ID]: number | null
    [OrderItemModel.ATTR_TAX_GROUP_ID]: number | null
    [OrderItemModel.ATTR_DEFAULT_CATEGORY_ID]: number | null
    [OrderItemModel.ATTR_CODE]: string | null
    [OrderItemModel.ATTR_SKU]: string | null
    [OrderItemModel.ATTR_EAN]: string | null
    [OrderItemModel.ATTR_UPC]: string | null
    [OrderItemModel.ATTR_JAN]: string | null
    [OrderItemModel.ATTR_ISBN]: string | null
    [OrderItemModel.ATTR_MPN]: string | null
    [OrderItemModel.ATTR_STOCKS_COUNT]: number
    [OrderItemModel.ATTR_POSITION]: number
    [OrderItemModel.ATTR_HEIGHT]: Length | null
    [OrderItemModel.ATTR_WIDTH]: Length | null
    [OrderItemModel.ATTR_DEPTH]: Length | null
    [OrderItemModel.ATTR_WEIGHT]: Weight | null
    [OrderItemModel.ATTR_VOLUME]: Volume | null
    [OrderItemModel.ATTR_UNIT_PRICE]: Monetary | null
    [OrderItemModel.ATTR_TAXED_UNIT_PRICE]: Monetary | null
    [OrderItemModel.ATTR_TOTAL_PRICE]: Monetary | null
    [OrderItemModel.ATTR_TAXED_TOTAL_PRICE]: Monetary | null
    [OrderItemModel.ATTR_PRICE]: Monetary | null
    [OrderItemModel.ATTR_TAXED_PRICE]: Monetary | null
    [OrderItemModel.ATTR_TAX_RATE]: Monetary
    [OrderItemModel.ATTR_CREATED_AT]: ApiDateTime
    [OrderItemModel.ATTR_UPDATED_AT]: ApiDateTime
    [OrderItemModel.ATTR_PRODUCT_ID]: number | null
    [OrderItemModel.ATTR_PARENT_ID]: number | null
    [OrderItemModel.ATTR_PRODUCT_VARIATION_ID]: number | null
    [OrderItemModel.ATTR_ORDER_ID]: number
    [OrderItemModel.ATTR_AMOUNT]: number
    [OrderItemModel.ATTR_PAYLOAD]: unknown
    [OrderItemModel.ATTR_ORDER_ITEM_TYPE]: number // TODO: some enum?
}

interface Embeds {
    [OrderItemModel.EMBED_PRODUCT_URLS]: string
    [OrderItemModel.EMBED_PRODUCT_IMAGE_URL]: string | null
}

export class OrderItemModel extends ApiModel<Attributes, Embeds> {
    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_VARIATION_NAME = 'variation_name'
    static readonly ATTR_BRAND_ID = 'brand_id'
    static readonly ATTR_PRICE_LIST_ID = 'price_list_id'
    static readonly ATTR_TAX_GROUP_ID = 'tax_group_id'
    static readonly ATTR_DEFAULT_CATEGORY_ID = 'default_category_id'
    static readonly ATTR_CODE = 'code'
    static readonly ATTR_SKU = 'sku'
    static readonly ATTR_EAN = 'ean'
    static readonly ATTR_UPC = 'upc'
    static readonly ATTR_JAN = 'jan'
    static readonly ATTR_ISBN = 'isbn'
    static readonly ATTR_MPN = 'mpn'
    static readonly ATTR_STOCKS_COUNT = 'stocks_count'
    static readonly ATTR_POSITION = 'position'
    static readonly ATTR_HEIGHT = 'height'
    static readonly ATTR_WIDTH = 'width'
    static readonly ATTR_DEPTH = 'depth'
    static readonly ATTR_WEIGHT = 'weight'
    static readonly ATTR_VOLUME = 'volume'
    static readonly ATTR_UNIT_PRICE = 'unit_price'
    static readonly ATTR_TAXED_UNIT_PRICE = 'taxed_unit_price'
    static readonly ATTR_TOTAL_PRICE = 'total_price'
    static readonly ATTR_TAXED_TOTAL_PRICE = 'taxed_total_price'
    static readonly ATTR_PRICE = 'price'
    static readonly ATTR_TAXED_PRICE = 'taxed_price'
    static readonly ATTR_TAX_RATE = 'tax_rate'
    static readonly ATTR_CREATED_AT = 'created_at'
    static readonly ATTR_UPDATED_AT = 'updated_at'
    static readonly ATTR_PRODUCT_ID = 'product_id'
    static readonly ATTR_PARENT_ID = 'parent_id'
    static readonly ATTR_PRODUCT_VARIATION_ID = 'product_variation_id'
    static readonly ATTR_ORDER_ID = 'order_id'
    static readonly ATTR_AMOUNT = 'amount'
    static readonly ATTR_PAYLOAD = 'payload'
    static readonly ATTR_ORDER_ITEM_TYPE = 'order_item_type'

    static readonly EMBED_PRODUCT_URLS = 'product_urls'
    static readonly EMBED_PRODUCT_IMAGE_URL = 'product_image_url'


    get id() {
        return this._getAttribute(OrderItemModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(OrderItemModel.ATTR_NAME)
    }

    get variationName() {
        return this._getAttribute(OrderItemModel.ATTR_VARIATION_NAME)
    }

    get brandId() {
        return this._getAttribute(OrderItemModel.ATTR_BRAND_ID)
    }

    get priceListId() {
        return this._getAttribute(OrderItemModel.ATTR_PRICE_LIST_ID)
    }

    get taxGroupId() {
        return this._getAttribute(OrderItemModel.ATTR_TAX_GROUP_ID)
    }

    get defaultCategoryId() {
        return this._getAttribute(OrderItemModel.ATTR_DEFAULT_CATEGORY_ID)
    }

    get code() {
        return this._getAttribute(OrderItemModel.ATTR_CODE)
    }

    get sku() {
        return this._getAttribute(OrderItemModel.ATTR_SKU)
    }

    get ean() {
        return this._getAttribute(OrderItemModel.ATTR_EAN)
    }

    get upc() {
        return this._getAttribute(OrderItemModel.ATTR_UPC)
    }

    get jan() {
        return this._getAttribute(OrderItemModel.ATTR_JAN)
    }

    get isbn() {
        return this._getAttribute(OrderItemModel.ATTR_ISBN)
    }

    get mpn() {
        return this._getAttribute(OrderItemModel.ATTR_MPN)
    }

    get stocksCount() {
        return this._getAttribute(OrderItemModel.ATTR_STOCKS_COUNT)
    }

    get position() {
        return this._getAttribute(OrderItemModel.ATTR_POSITION)
    }

    get height() {
        return this._getAttribute(OrderItemModel.ATTR_HEIGHT)
    }

    get width() {
        return this._getAttribute(OrderItemModel.ATTR_WIDTH)
    }

    get depth() {
        return this._getAttribute(OrderItemModel.ATTR_DEPTH)
    }

    get weight() {
        return this._getAttribute(OrderItemModel.ATTR_WEIGHT)
    }

    get volume() {
        return this._getAttribute(OrderItemModel.ATTR_VOLUME)
    }

    get unitPrice() {
        return this._getAttribute(OrderItemModel.ATTR_UNIT_PRICE)
    }

    get taxedUnitPrice() {
        return this._getAttribute(OrderItemModel.ATTR_TAXED_UNIT_PRICE)
    }

    get totalPrice() {
        return this._getAttribute(OrderItemModel.ATTR_TOTAL_PRICE)
    }

    get taxedTotalPrice() {
        return this._getAttribute(OrderItemModel.ATTR_TAXED_TOTAL_PRICE)
    }

    get price() {
        return this._getAttribute(OrderItemModel.ATTR_PRICE)
    }

    get taxedPrice() {
        return this._getAttribute(OrderItemModel.ATTR_TAXED_PRICE)
    }

    get taxRate() {
        return this._getAttribute(OrderItemModel.ATTR_TAX_RATE)
    }

    get createdAt() {
        return this._getAttribute(OrderItemModel.ATTR_CREATED_AT)
    }

    get updatedAt() {
        return this._getAttribute(OrderItemModel.ATTR_UPDATED_AT)
    }

    get productId() {
        return this._getAttribute(OrderItemModel.ATTR_PRODUCT_ID)
    }

    get parentId() {
        return this._getAttribute(OrderItemModel.ATTR_PARENT_ID)
    }

    get productVariationId() {
        return this._getAttribute(OrderItemModel.ATTR_PRODUCT_VARIATION_ID)
    }

    get orderId() {
        return this._getAttribute(OrderItemModel.ATTR_ORDER_ID)
    }

    get amount() {
        return this._getAttribute(OrderItemModel.ATTR_AMOUNT)
    }

    get payload() {
        return this._getAttribute(OrderItemModel.ATTR_PAYLOAD)
    }

    get orderItemType() {
        return this._getAttribute(OrderItemModel.ATTR_ORDER_ITEM_TYPE)
    }

    get productUrls() {
        return this._getEmbed(OrderItemModel.EMBED_PRODUCT_URLS)
    }

    get productImageUrl() {
        return this._getEmbed(OrderItemModel.EMBED_PRODUCT_IMAGE_URL)
    }

    // ----------------------------------------

    getProductUrl() {
        return this.productUrls
    }

}
