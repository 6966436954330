import { defineStore } from 'pinia'
import { CustomerModel } from '@simploshop-models/customer.model'
import { MeModel } from '@simploshop-models/me.model'

export const useAuthStore = defineStore('auth', () => {
    const _meModel = ref<InstanceType<typeof MeModel> | null | undefined>(undefined)

    // public properties
    const isLoggedIn = computed(() => !!(_meModel.value?.customer))
    const customer = computed(() => _meModel.value?.customer ?? null)

    /**
     * Set the customer or me model.
     * If the visitor is a guest (not logged-in), the customer will be set to `null`
     *
     * @param val the `MeModel` or `CustomerModel` to set
     */
    function setCustomer(val: MeModel | CustomerModel | null) {
        // if a MeModel is passed, set it directly
        if (val instanceof MeModel || val === null) {
            _meModel.value = val
            return
        }

        // if a CustomerModel is passed, update it in the existing MeModel
        if (!_meModel.value) return
        _meModel.value.customer = val
    }

    function removeCustomer() {
        setCustomer(null)
    }

    return {
        _meModel, // Needs to be returned to be sent in the payload
        customer,
        isLoggedIn,
        setCustomer,
        removeCustomer,
    }
})
