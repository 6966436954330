import { ApiModel } from '@composable-api/api.model'
import type { CustomerProductListType } from '@sim-api-enums/customer'

interface Attributes {
    [CustomerProductListModel.ATTR_ID]: string
    [CustomerProductListModel.ATTR_CUSTOMER_ID]: number
    [CustomerProductListModel.ATTR_NAME]: string | null
    [CustomerProductListModel.ATTR_DESCRIPTION]: string | null
    [CustomerProductListModel.ATTR_TYPE]: CustomerProductListType
    [CustomerProductListModel.ATTR_POSITION]: number
}

export class CustomerProductListModel extends ApiModel<Attributes> {
    static readonly ATTR_ID = 'id'
    static readonly ATTR_CUSTOMER_ID = 'customer_id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_DESCRIPTION = 'description'
    static readonly ATTR_TYPE = 'type'
    static readonly ATTR_POSITION = 'position'

    get id() {
        return this._getAttribute(CustomerProductListModel.ATTR_ID)
    }

    get customerId() {
        return this._getAttribute(CustomerProductListModel.ATTR_CUSTOMER_ID)
    }

    get name() {
        return this._getAttribute(CustomerProductListModel.ATTR_NAME)
    }

    get description() {
        return this._getAttribute(CustomerProductListModel.ATTR_DESCRIPTION)
    }

    get type() {
        return this._getAttribute(CustomerProductListModel.ATTR_TYPE)
    }

    get position() {
        return this._getAttribute(CustomerProductListModel.ATTR_POSITION)
    }
}
