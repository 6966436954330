export function errorLog(...args: any[]) {
    if (args.length > 1) {
        console.error(`%c${args[0]}`, `
                  background: ${'#ED135C'};
                  border-radius: 0.5em;
                  color: white;
                  font-weight: bold;
                  padding: 2px 0.5em;
                `, ...args.slice(1))
        return
    }

    console.error(`%c${args[0]}`, `
                  background: ${'#ED135C'};
                  border-radius: 0.5em;
                  color: white;
                  font-weight: bold;
                  padding: 2px 0.5em;
                `)
}

export function warnLog(...args: any[]) {
    if (args.length > 1) {
        console.warn(`%c${args[0]}`, `
                  background: ${'#F39C12'};
                  border-radius: 0.5em;
                  color: white;
                  font-weight: bold;
                  padding: 2px 0.5em;
                `, ...args.slice(1))
        return
    }

    console.warn(`%c${args[0]}`, `
                  background: ${'#F39C12'};
                  border-radius: 0.5em;
                  color: white;
                  font-weight: bold;
                  padding: 2px 0.5em;
                `)
}
