import { ApiService, type ApiServiceFetchOptions } from '@composable-api/service/api.service'
import { ApiReactiveService, type ApiServiceUseFetchOptions } from '@composable-api/service/api.reactive-service'
import type { ApiModel } from '@composable-api/api.model'
import type { ApiModelAttributes } from '@composable-api/api.model'
import { GoPayModel } from '../models/go-pay.model'

class GopayRequestsApiService<M extends ApiModel> extends ApiService<M> {
    post(data: GoPayPayload, options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'POST',
            body: data,
        })
    }
}

export function getGoPayApiService() {
    return new GopayRequestsApiService(
        {
            endpoint: '/gopay-requests',
        },
        GoPayModel
    )
}

interface GoPayPayload {
    [GoPayModel.ATTR_ORDER_ID]: ApiModelAttributes<GoPayModel>['order_id']
    [GoPayModel.ATTR_PAYLOAD]: ApiModelAttributes<GoPayModel>['payload']
    /**
     * The URL to return to after the payment is processed.
     *
     * This needs to be a full URL, including the protocol & the hostname.
     * The user will get redirected to this page even if the payment fails.
     */
    return_url: string
}

// --------------------------------------------------------------------------------------------------------------

class GoPayRequestsApiReactiveService<M extends ApiModel> extends ApiReactiveService<M> {
    useHandleReturn<T extends boolean>(data: GoPayReturnHandlerPayload, options?: ApiServiceUseFetchOptions<M, T>) {
        return this
            .addRouteParam('handle-return')
            .useFetch({
                ...options,
                method: 'POST',
                body: data,
            })
    }
}

export function useGoPayRequestsApiService() {
    return new GoPayRequestsApiReactiveService(
        {
            endpoint: '/gopay-requests',
        },
        GoPayModel
    )
}

interface GoPayReturnHandlerPayload {
    external_id: ApiModelAttributes<GoPayModel>['external_id']
}
