export default defineNuxtPlugin({
    name: 'auth',
    parallel: true,
    setup: async (nuxtApp) => {
        const authStore = useAuthStore()
        const { getThemePath, isThemePath } = useThemePaths()

        // responsible for:
        // - redirecting the user to the login page when they logged out
        //   while being on a page that requires auth
        //   (to prevent them from accessing the page while not being logged in)
        const currentRoute = useRoute()
        const redirectToPath = useStateRedirectToPath()
        const localePath = useLocalePath()

        watch(() => authStore.isLoggedIn, async (isLoggedIn) => {
            if (!isLoggedIn && doesRouteRequireAuth(currentRoute)) {
                // .............. USER SIGNED OUT WHILE BEING ON A PAGE THAT REQUIRES AUTH ...............
                // save the route to redirect to
                redirectToPath.value = currentRoute.fullPath
                // navigate to the login page
                await navigateTo(getThemePath('login'))
            } else if (isLoggedIn && isThemePath('login')) {
                // ................... USER SIGNED IN WHILE BEING ON THE LOGIN PAGE ......................
                // get the path to redirect to
                const pathToRedirectTo = redirectToPath.value || localePath('/')
                // navigate to the previous page that required auth or to the home page
                await navigateTo(pathToRedirectTo)
            }
        })
    },
})
