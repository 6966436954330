import { defineStore } from 'pinia'
import { CustomerProductListModel } from '@simploshop-models/customer-product-list.model'
import { CustomerProductListType } from '@sim-api-enums/customer'

export const useProductWishlistStore = defineStore('product-wishlist', () => {
    const { syncedMe, me, customer } = useAuth()
    const { notifyError } = useNotifications()
    const nuxtApp = useNuxtApp()

    const productWishlistId = ref<string | null>(me.value?.customerProductWishList?.id ?? null)
    watch(syncedMe, (val) => {
        refreshProductWishlistId(val as InstanceType<typeof MeModel>)
    })

    function refreshProductWishlistId(val?: InstanceType<typeof MeModel>) {
        productWishlistId.value = (val ?? me.value)?.customerProductWishList?.id ?? null
        productWishlistPromise = null
    }

    const customerProductListsService = useCustomerProductListsApiService()

    let productWishlistPromise: Promise<InstanceType<typeof CustomerProductListModel> | null> | null = null

    /**
     * Creates a new wishlist if one does not already exist.
     *
     * The function will automatically show error notifications if the customer is not logged in
     * or if the wishlist creation fails.
     * @returns The created wishlist or `null` if the customer is not logged in.
     */
    async function createProductWishlist(): Promise<InstanceType<typeof CustomerProductListModel> | null> {
        // skip if wishlist already exists or the customer is not logged in
        if (productWishlistId.value) return null
        // return the current promise if the list is already being created
        if (productWishlistPromise) return productWishlistPromise

        productWishlistPromise = new Promise(async resolve => {
            // customer not logged in
            if (!customer.value?.id) {
                notifyError(nuxtApp.$i18n.t('_core_theme.errors.must_be_logged_in_to_create_product_list'))
                return null
            }

            try {
                const response = await customerProductListsService.post({
                    customer_id: customer.value.id,
                    name: 'wishlist',
                    description: null,
                    type: CustomerProductListType.WISH_LIST,
                    position: null,
                })

                const productList = response.getItem()
                // assign to the store after creation
                productWishlistId.value = productList?.id ?? null
                resolve(productList)
            } catch (e) {
                let errorMessage: string | null = null
                if (e instanceof ApiResponseError) {
                    errorMessage = e.getErrorMessage()
                }
                notifyError(errorMessage || nuxtApp.$i18n.t('_core_theme.errors.failed_to_create_product_list'))
                resolve(null)
            }
        })

        return productWishlistPromise
    }

    return {
        productWishlistId,
        createProductWishlist,
        refreshProductWishlistId,
    }
})
