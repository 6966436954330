export enum PaymentService {
    NONE = 0,
    WIRE_TRANSFER = 1,
    WEB_PAY = 2,
    CASH_ON_DELIVERY = 3,
    GO_PAY = 4,
    PAYMENT_UPON_PICKUP = 5,
    PAYMENT_BY_INVOICE = 6,
    PAYS = 7,
}
