
const LOADED_ATTR = 'data-loaded'

interface LoadScriptOptions<T> {
    /**
     * A callback that will be called when the script has been loaded.
     */
    onLoad: () => T
}

/**
 * A utility function that wraps the loading of a script in a promise.
 * This function is meant to be used only on the client side & will
 * return a promise that resolves when the script has been loaded.
 *
 * Only use this when the `document` object is available.
 *
 * On the server side, it will return a resolved promise.
 * @param src the URL of the script to load
 * @param options the options for the script
 * @throws Error if the script fails to load
 */
export function loadScript<T>(src: string, options?: Partial<LoadScriptOptions<T>>): Promise<T | undefined> {
    if (!import.meta.client || !document) return Promise.resolve(undefined)

    return new Promise((resolve, reject) => {
        let shouldAppend = false

        let el: HTMLScriptElement | null = document.querySelector(`script[src="${src}"]`)
        if (!el) {
            el = document.createElement('script')
            el.type = 'text/javascript'
            el.async = true
            el.src = src
            shouldAppend = true
        } else if (el.hasAttribute(LOADED_ATTR)) {
            resolve(options?.onLoad?.() ?? undefined)
            return
        }

        const onLoad = () => {
            el?.setAttribute(LOADED_ATTR, 'true')
            cleanup()
            resolve(options?.onLoad?.() ?? undefined)
        }

        const onError = (event: Event) => {
            cleanup()
            reject(new Error(`Failed to load script: '${src}'.`))
        }

        const cleanup = () => {
            el?.removeEventListener('error', onError)
            el?.removeEventListener('abort', onError)
            el?.removeEventListener('load', onLoad)
        }

        el.addEventListener('error', onError)
        el.addEventListener('abort', onError)
        el.addEventListener('load', onLoad)

        if (shouldAppend) {
            document.head.appendChild(el)
        }
    })
}
