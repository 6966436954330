import type { RouterConfig } from '@nuxt/schema'
import type { RouteLocationNormalized, RouterScrollBehavior } from 'vue-router'
import { isChangingPage } from '#app/components/utils'
import { appPageTransition as defaultPageTransition } from '#build/nuxt.config.mjs'

type ScrollPosition = Awaited<ReturnType<RouterScrollBehavior>>

export default <RouterConfig>{
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            // get the header element, if it exists
            const header: HTMLElement | undefined = document.getElementsByTagName('header')[0]
            // determine the height of the header or default to '0'
            const headerHeight = header?.offsetHeight ?? 0

            return {
                el: to.hash,
                top: headerHeight + 32,
                behavior: 'smooth',
            }
        }

        // ---------------------------------------------------------
        // NUXT SECTION

        const nuxtApp = useNuxtApp()
        // @ts-expect-error untyped, nuxt-injected option
        const behavior = useRouter().options?.scrollBehaviorType ?? 'auto'

        // By default, when the returned position is falsy or an empty object, vue-router will retain the current scroll position
        // savedPosition is only available for popstate navigations (back button)
        let position: ScrollPosition = savedPosition || undefined

        const routeAllowsScrollToTop = typeof to.meta.scrollToTop === 'function' ? to.meta.scrollToTop(to, from) : to.meta.scrollToTop

        // Scroll to top if route is changed by default
        if (!position && from && to && routeAllowsScrollToTop !== false && isChangingPage(to, from)) {
            position = {
                left: 0,
                top: 0,
            }
        }

        // Hash routes on the same page, no page hook is fired so resolve here
        if (to.path === from.path) {
            if (from.hash && !to.hash) {
                return {
                    left: 0,
                    top: 0,
                }
            }
            if (to.hash) {
                return {
                    el: to.hash,
                    top: _getHashElementScrollMarginTop(to.hash),
                    behavior: behavior,
                }
            }
            // The route isn't changing so keep current scroll position
            return false
        }

        // Wait for `page:transition:finish` or `page:finish` depending on if transitions are enabled or not
        const hasTransition = (route: RouteLocationNormalized) => !!(route.meta.pageTransition ?? defaultPageTransition)
        const hookToWait = (hasTransition(from) && hasTransition(to)) ? 'page:transition:finish' : 'page:finish'
        return new Promise((resolve) => {
            nuxtApp.hooks.hookOnce(hookToWait, async () => {

                // TODO: This line below was probably necessary for some reason, that's why they probably added it in Nuxt, however
                //       it's causing a bug where the page would change and only after the next event loop would the scroll happen
                //       causing the footer (usually) to be visible for a split second before the scroll happens (jarring)
                //       -> maybe come back to it later if any issues arise
                //       edit: adjusted based on https://github.com/nuxt/nuxt/pull/25817#issuecomment-1959611706

                // await new Promise(resolve => setTimeout(resolve, 0))
                await new Promise(resolve => requestAnimationFrame(resolve))
                if (to.hash) {
                    position = {
                        el: to.hash,
                        top: _getHashElementScrollMarginTop(to.hash),
                        behavior: behavior,
                    }
                }
                resolve(position)
            })
        })

        // --------------------------------------------------------- END OF NUXT SECTION

        // return (async () => {
        // // wait for the async top-level script setup of the next page to complete
        //     return savedPosition || { left: 0, top: 0 }
        // })()
    },
}

function _getHashElementScrollMarginTop(selector: string): number {
    try {
        const elem = document.querySelector(selector)
        if (elem) {
            return Number.parseFloat(getComputedStyle(elem).scrollMarginTop)
        }
    } catch {
        // ignore any errors parsing scrollMarginTop
    }
    return 0
}
