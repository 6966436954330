import { type ServerCache } from '../../types/module'
import { errorLog } from '../../utils/logging'
import { useConfig } from '../../private/config'
import { useStateHeaders } from '../../private/state'

interface ServerCacheSetDataOptions {
    /**
     * The time in seconds after which the cache entry should expire.
     */
    ttl: number | null
}

/**
 * A composable that provides methods to interact with the server cache.
 * These methods can only be used on the server side in the Nuxt app context.
 * Do not use them in the nitro part of the app.
 */
export function useServerCache() {
    const _config = useConfig()
    const headers = useStateHeaders()

    async function getData<K extends keyof ServerCache>(key: K) {
        if (!import.meta.server) {
            errorLog('useServerCache() has been called on the client side. This is not the intended use of this composable and is most certainly a mistake.')
            return null    // return `null` on client side
        }

        return await $fetch(`/api/${_config.moduleApiPrefix}/cache/${key}`, {
            headers: headers.value as any,
        }) as ServerCache[K]
    }

    function getCacheUrl<K extends keyof ServerCache>(key: K) {
        return `/api/${_config.moduleApiPrefix}/cache/${key}`
    }

    async function setData<K extends keyof ServerCache>(key: K, data: ServerCache[K], options?: Partial<ServerCacheSetDataOptions>) {
        if (!import.meta.server) {
            errorLog('useServerCache() has been called on the client side. This is not the intended use of this composable and is most certainly a mistake.')
            return
        }

        await $fetch(`/api/${_config.moduleApiPrefix}/cache/${key as string}`, {
            method: 'POST',
            body: data,
            headers: headers.value as any,
            query: {
                ttl: options?.ttl ?? undefined,
            },
        })
    }

    async function resetCache<K extends keyof ServerCache>(key: K) {
        if (!import.meta.server) {
            errorLog('useServerCache() has been called on the client side. This is not the intended use of this composable and is most certainly a mistake.')
            return
        }

        await $fetch(`/api/${_config.moduleApiPrefix}/cache/${key as string}`, {
            method: 'DELETE',
        })
    }


    return {
        getData,
        setData,
        getCacheUrl,
    }
}
