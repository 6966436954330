import type { Directive, DirectiveBinding } from 'vue'

// TODO: Add support for transition triggering like the native `v-show` / `v-if` directives
export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('visibility', <Directive<HTMLElement, boolean>>{
        beforeMount: handleVisibility,
        updated: handleVisibility,
        getSSRProps: (binding) => {
            return {
                style: {
                    visibility: binding.value ? undefined : 'hidden',
                },
            }
        },
    })
})

const handleVisibility = (el: HTMLElement, binding: DirectiveBinding<boolean>) => {
    el.style.visibility = binding.value ? '' : 'hidden'
}
