/**
 * Get the only hostname of a url.
 * @example
 * getUrlHostname('https://example.com/path') // 'example.com'
 * @param url the url to get the hostname from
 */
export function getUrlHostname(url: string) {
    return new URL(url).hostname
}


/**
 * Get the hostname of the domain of the currently selected locale.
 * Returns `null` if the locale wasn't found, or it doesn't have a domain.
 *
 * !! IMPORTANT !! nuxt app parameter isn't type-safe
 *
 * @param nuxtApp the Nuxt app instance
 * @todo correctly type nuxt app
 * @todo handle the case when there are locales without a domain
 * @todo change to use the base url in production (or use the base url as a fallback)
 */
export function getI18nCurrentLocaleHostname<T>(nuxtApp: T) {
    // TODO: consider changing this to module config (current locale + locales)
    // @ts-ignore $i18n doesn't have correct type and the theme might not have the $i18n module
    const currentLocaleDomainFromConfig = nuxtApp.$i18n?.locales.value.find(locale =>
        // @ts-ignore $i18n doesn't have correct type and the theme might not have the $i18n module
        locale.code === nuxtApp.$i18n?.locale.value
    )?.domain

    // TODO: change to use the base url in production (or use the base url as a fallback)

    if (!currentLocaleDomainFromConfig) return null

    return getUrlHostname(currentLocaleDomainFromConfig)
}

export function getConfigPublicApiUrl<T>(nuxtApp: T) {
    // @ts-ignore $config doesn't have correct type and the theme might not have the $i18n module
    const currentLocaleDomainFromConfig = nuxtApp.$config.public.composableApi.apiUrl

    if (!currentLocaleDomainFromConfig) return null

    return getUrlHostname(currentLocaleDomainFromConfig)
}
