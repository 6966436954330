import { ApiModel } from '@composable-api/api.model'
import type { ApiDateTime } from '../types/general-data'

interface Attributes {
    [CurrencyModel.ATTR_ID]: string
    [CurrencyModel.ATTR_NAME]: string
    [CurrencyModel.ATTR_DECIMAL_PLACES]: number
    [CurrencyModel.ATTR_NUMERIC_CODE]: number | null
    [CurrencyModel.ATTR_SYMBOL]: string | null
    [CurrencyModel.ATTR_IS_ACTIVE]: boolean
    [CurrencyModel.ATTR_IS_DEFAULT]: boolean
    [CurrencyModel.ATTR_CREATED_AT]: ApiDateTime
}

interface Embeds {
}

export class CurrencyModel extends ApiModel<Attributes, Embeds> {
    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_DECIMAL_PLACES = 'decimal_places'
    static readonly ATTR_NUMERIC_CODE = 'numeric_code'
    static readonly ATTR_SYMBOL = 'symbol'
    static readonly ATTR_IS_ACTIVE = 'is_active'
    static readonly ATTR_IS_DEFAULT = 'is_default'
    static readonly ATTR_CREATED_AT = 'created_at'

    get id() {
        return this._getAttribute(CurrencyModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(CurrencyModel.ATTR_NAME)
    }

    get decimalPlaces() {
        return this._getAttribute(CurrencyModel.ATTR_DECIMAL_PLACES)
    }

    get numericCode() {
        return this._getAttribute(CurrencyModel.ATTR_NUMERIC_CODE)
    }

    get symbol() {
        return this._getAttribute(CurrencyModel.ATTR_SYMBOL)
    }

    get isActive() {
        return this._getAttribute(CurrencyModel.ATTR_IS_ACTIVE)
    }

    get isDefault() {
        return this._getAttribute(CurrencyModel.ATTR_IS_DEFAULT)
    }

    get createdAt() {
        return this._getAttribute(CurrencyModel.ATTR_CREATED_AT)
    }
}
