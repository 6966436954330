<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
        <path fill="currentColor"
            d="M4.07 4.722c1.496-1.389 3.486-2.5 5.93-2.5 2.444 0 4.434 1.111 5.93 2.5 1.497 1.39 2.497 3.056 2.959 4.167-.462 1.111-1.462 2.778-2.958 4.167-1.497 1.388-3.487 2.5-5.931 2.5s-4.434-1.112-5.93-2.5C2.572 11.665 1.572 10 1.11 8.889c.463-1.111 1.463-2.779 2.96-4.167ZM10 1.112c-2.806 0-5.052 1.277-6.688 2.798C1.687 5.417.601 7.222.087 8.462a1.1 1.1 0 0 0 0 .854c.514 1.24 1.6 3.045 3.225 4.552 1.636 1.52 3.882 2.799 6.688 2.799 2.806 0 5.052-1.278 6.688-2.799 1.625-1.51 2.711-3.313 3.229-4.552.114-.274.114-.58 0-.854-.518-1.24-1.605-3.045-3.23-4.552-1.634-1.521-3.881-2.8-6.687-2.8ZM6.667 8.888a3.333 3.333 0 1 1 6.666 0 3.333 3.333 0 0 1-6.666 0Zm7.777 0a4.444 4.444 0 1 0-8.888 0 4.444 4.444 0 0 0 8.888 0Z"
        />
    </svg>
)
</script>
