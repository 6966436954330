<template>
    <div class="sim-main-search">
        <BaseUiInput
            ref="inputComponentRef"
            v-model="query"
            :color="color"
            :size="size === 'compact' ? 'sm' : 'md'"
            :placeholder="$t('labels.search')"
            autocomplete="off"
            square
            clearable
            @keyup.enter="handleEnter"
        >
            <template #leading>
                <IconSearch width="24" height="24" />
            </template>
        </BaseUiInput>

        <!-- RESULTS POPUP -->
        <LazyMainSearchResultsPopup
            v-if="wasResultsPopupMounted && $refs.inputComponentRef"
            v-model="isResultsPopupOpen"
            v-model:query="syncedQuery"
            :anchor="$refs.inputComponentRef as unknown as HTMLElement"
        />
    </div>
</template>

<script lang="ts" setup>
import type { BaseUiInput } from '#components'
import type { ComponentInstance } from '@core/types/utility'
import type { BaseUiInputColors } from './base/BaseUiInput.vue'

const {
    size = 'compact',
    color = 'basic',
} = defineProps<{
    size?: 'compact' | 'normal'
    color?: BaseUiInputColors
}>()

const emit = defineEmits<{
    popupChange: [boolean]
}>()

const query = ref<string>('')
const _syncedQuery = ref<string>('')
const syncedQuery = computed({
    get() {
        return _syncedQuery.value
    },
    set(value) {
        query.value = value
        syncQuery()
    },
})

function syncQuery() {
    _syncedQuery.value = query.value
}

const syncQueryDebounced = useDebounceFn(syncQuery, 500)

watch(query, (val) => {
    if (!val.trim()) {
        syncQuery()
        return
    }

    isResultsPopupOpen.value = true
    syncQueryDebounced()
})

function handleEnter() {
    syncQuery()
    isResultsPopupOpen.value = true
}

const { is: isResultsPopupOpen, was: wasResultsPopupMounted } = useLazyMount()
watch(isResultsPopupOpen, (val) => {
    emit('popupChange', val)
})

const inputComponentRef = ref<ComponentInstance<typeof BaseUiInput> | null>(null)
const { focused } = useFocusWithin(inputComponentRef as Parameters<typeof useFocusWithin>[0])

watch(focused, (isFocused) => {
    if (!isFocused) return

    // delay the opening in case the clear button was clicked
    // (not to open it immediately)
    setTimeout(() => {
        if (!focused.value) return
        isResultsPopupOpen.value = true
    }, 100)
})

const route = useRoute()
watch(() => route.path, () => {
    isResultsPopupOpen.value = false
})

function focusInput() {
    // @ts-ignore
    inputComponentRef.value?.focusInput()
}

defineExpose({
    focusInput,
})


</script>

<style lang="scss" scoped>

.sim-main-search {
    @include more-than(lg) {
        width: min(100%, 32rem);
        max-width: 32rem;   // TODO: fixes a bug where the search input would start out as 100% even though it was bigger
                            // (investigate)
    }
}

</style>
