/**
 * A composable to handle custom app events.
 *
 * ## Usage
 * To run a callback when an event is emitted, use the `on` or `useOn` functions.
 * The event callbacks can be async and there is an option to await the completion of all callbacks.
 * (note that the callbacks are run in parallel)
 *
 * It is possible to listen for the events anywhere in the app, however, if the event were
 * to be emitted before the listener is set up, the event would be missed.
 *
 * ### Example usage:
 * ```ts
 * // Listen to an event
 * const unregister = on('cart:item-added', ({ item }) => {
 *    console.log('Item added to cart:', item)
 * })
 *
 * // Remove the listener
 * unregister()
 *
 * // Listen to an event and automatically unregister the listener after the first call
 * on('cart:item-added', ({ item }) => {
 *   console.log('Item added to cart:', item)
 * }, { once: true })
 *
 * // Listen to an event and automatically unregister the listener when the component is unmounted
 * useOn('cart:item-added', ({ item }) => {
 *  console.log('Item added to cart:', item)
 * })
 *
 * // Emit an event
 * emit('cart:item-added', { item: cartItem })
 * // Emit an event and await the completion of all callbacks
 * await emit('cart:item-added', { item: cartItem })
 * ```
 *
 *
 * ## Setup
 * To set up this composable, the `Events` interface from `@core-types/events` needs to be extended.
 * This can be done in the following way:
 * ```ts
 * // index.d.ts
 * declare module '@core-types/events' {
 *     interface Events {
 *         'cart:item-added': { item: CartItemModel }
 *     }
 * }
 *
 * export {}
 * ```
 *
 * You will then be able to use the provided events with full type-safety.
 */
export function useEvents() {
    const store = useEventsStore()
    return {
        on: store.on,
        useOn: store.useOn,
        emit: store.emit,
    }
}
