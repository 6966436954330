import { ApiService, type ApiServiceFetchOptions } from '@composable-api/service/api.service'
import type { ApiModel } from '@composable-api/api.model'
import { CustomerPasswordModel } from '../models/customer-password.model'

class CustomerPasswordsApiService<M extends ApiModel> extends ApiService<M> {
    post(data: { email: string }, options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'POST',
            body: data,
        })
    }

    put(data: { token: string, new_password: string, new_password_confirmation: string }, options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'PUT',
            body: data,
        })
    }
}

export function getCustomerPasswordsApiService() {
    return new CustomerPasswordsApiService({
        endpoint: '/passwords/customer',
    }, CustomerPasswordModel)
}
