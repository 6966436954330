import type { GoPay } from '@core-theme/types/third-party/gopay'

interface UseClientGoPayOptions {
    /**
     * Whether to load the sandbox version of the GoPay script.
     * @default false
     */
    sandbox: boolean
}

interface CheckoutOptions {
    /**
     * Whether to use the inline payment gateway if available.
     * When a payment method, which doesn't support the inline gateway, is selected,
     * GoPay will redirect the user to the full-page gateway.
     *
     * @default true
     */
    inline: boolean
}

export function useClientGoPay(options?: Partial<UseClientGoPayOptions>) {
    const { $i18n } = useNuxtApp()
    const notifications = useNotifications()

    const goPayScriptPromise = useState<Promise<GoPay | null | undefined> | null>('gopay-p', () => null)
    async function getGoPay() {
        if (goPayScriptPromise.value !== null) {
            return goPayScriptPromise.value
        }

        const scriptUrl = options?.sandbox
            ? 'https://gw.sandbox.gopay.com/gp-gw/js/embed.js'
            : 'https://gate.gopay.cz/gp-gw/js/embed.js'

        goPayScriptPromise.value = loadScript(scriptUrl, {
            onLoad: () => {
                return (window as typeof window & { _gopay: GoPay })._gopay ?? null
            },
        })

        return goPayScriptPromise.value
    }

    /**
     * Handles the GoPay payment process.
     * Opens the gateway iframe or redirects the user to the full-page gateway.
     * @param returnUrl
     * @param options
     * @throws Error if the GoPay script fails to load.
     */
    async function checkout(returnUrl: string | Promise<string | null>, options?: Partial<CheckoutOptions>) {
        const [
            url,
            goPay,
        ] = await Promise.all([
            Promise.resolve(returnUrl),
            getGoPay(),
        ])

        if (!goPay) {
            notifications.notifyError($i18n.t('_core_theme.errors.go_pay_load_error'))
            throw new Error('[useClientGoPay]: GoPay script failed to load.')
        }

        if (!url) {
            notifications.notifyError($i18n.t('_core_simploshop.errors.invalid_return_url'))
            throw new Error('[useClientGoPay]: Invalid return URL.')
        }

        return await new Promise<void>(resolve => {
            goPay.checkout({
                gatewayUrl: url,
                inline: options?.inline ?? true,
            }, (checkoutResult) => {
                resolve()
            })
        })

    }

    return {
        checkout,
    }
}
