<script lang="tsx">
import { defineComponentBaseUiButton } from '@core/app/components/base/ui/BaseUiButton.vue'
import type { ButtonVariants } from '@core/types/components'

type Colors = 'basic' | 'basic--dark' | 'primary' | 'success' | 'warning' | 'danger' | 'purple' | 'simplo'
type Variants = ButtonVariants | 'plain'
type Sizes = 'small' | 'medium' | 'large' | 'giant' | 'ultra' | 'none'

export default defineComponentBaseUiButton<Colors, Variants, Sizes>({
    props: {
        size: {
            default: 'medium',
        },
        color: {
            default: 'basic',
        },
        variant: {
            default: 'solid',
        },
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiButton.scss" as *;

@include button {
    border-radius: 0.75rem;

    line-height: normal;
    letter-spacing: 0.01em;

    transition: background-color $sim-trans-time-normal $sim-timing, color $sim-trans-time-normal $sim-timing;
}

@include button--disabled {
    background-color: $sim-c-basic-400;
}

@include button--variant(outlined) {
    @include button--disabled('&') {
        opacity: 0.5;
    }
}

@include button--variant(plain) {
    @include button--disabled('&') {
        background-color: transparent;
        opacity: 0.5;
    }
}

@include button--variant(bare) {
    border-radius: 0;

    @include button--disabled('&') {
        background-color: transparent;
        opacity: 0.5;
    }
}

@include button--variant(outlined) {
    border: 2px solid var(--outline-color);
}

@include button--variant(outlined) {
    @include content {
        margin: -2px;
    }
}

// --------------------------------------------------------------------
// Button sizes

@include button--size(small) {
    padding: 0.5rem 1rem;

    font-size: 0.75rem;
    font-weight: 500;

    border-radius: $sim-border-radius-xs;

    @include button--square('&') {
        padding: 0.5rem;
    }
}

@include button--size(medium) {
    padding: 0.625rem 1.25rem;

    font-size: 0.875rem;
    font-weight: 500;

    @include button--dense('&') {
        padding: 0.25rem 1rem;
    }
}

@include button--size(large) {
    padding: 0.8125rem 1.25rem;

    font-size: 1rem;
    font-weight: 500;

    @include button--dense('&') {
        padding: 0.5rem 1.25rem;
    }
}

@include button--size(giant) {
    padding: 1rem 1.25rem;

    font-size: 1.125rem;
    font-weight: 500;
}

@include button--size(ultra) {
    padding: 1.25rem 2.25rem;

    font-size: 1rem;
    font-weight: 500;
}


// --------------------------------------------------------------------
// Button colors

@include button--color('basic') {
    @include button--variant(solid, '&') {
        background-color: $sim-c-white;
        color: $sim-c-black;

        @include button--hover('&') {
            background-color: $sim-c-basic-300;
        }
    }

    @include button--variant(plain, '&') {
        @include button--hover('&') {
            background-color: $sim-c-basic-100;
            transition-duration: $sim-trans-time-short;
        }
    }

    @include button--variant(outlined, '&') {
        color: $sim-c-primary-800;
    }
}

@include button--color('basic--dark') {
    @include button--variant('plain', '&') {
        @include button--hover('&') {
            background-color: $sim-c-basic-300;
            transition-duration: $sim-trans-time-short;
        }

        @include button--loading('&') {
            color: $sim-c-basic-600;
        }
    }
}

@include button--color('primary') {
    @include button--variant(solid, '&') {
        background-color: $sim-c-primary-800;
        color: $sim-c-white;

        @include button--hover('&') {
            background-color: $sim-c-primary-600;
        }
    }

    @include button--variant(outlined, '&') {
        background-color: $sim-c-white;
        color: $sim-c-primary-800;

        --outline-color: #{$sim-c-primary-800};

        @include button--hover('&') {
            background-color: $sim-c-primary-800;
            color: $sim-c-white;
        }
    }

    @include button--variant(plain, '&') {
        color: $sim-c-primary-800;

        @include button--hover('&') {
            background-color: $sim-c-basic-200;
        }

        @include button--loading('&') {
            color: $sim-c-basic-600;
        }
    }
}

@include button--color('success') {
    @include button--variant(solid, '&') {
        background-color: $sim-c-success-500;
        color: $sim-c-white;

        @include button--hover('&') {
            background-color: $sim-c-success-600;
        }
    }

    @include button--variant(outlined, '&') {
        background-color: $sim-c-white;
        color: $sim-c-success-500;

        --outline-color: #{$sim-c-success-500};

        @include button--hover('&') {
            background-color: $sim-c-success-500;
            color: $sim-c-white;
        }
    }

    @include button--variant(plain, '&') {
        color: $sim-c-success-500;
    }
}

@include button--color('warning') {
    @include button--variant(solid, '&') {
        background-color: $sim-c-warning-500;
        color: $sim-c-white;

        @include button--hover('&') {
            background-color: $sim-c-warning-400;
        }
    }

    @include button--variant(outlined, '&') {
        background-color: $sim-c-white;
        color: $sim-c-warning-500;

        --outline-color: #{$sim-c-warning-500};

        @include button--hover('&') {
            background-color: $sim-c-warning-500;
            color: $sim-c-white;
        }
    }

    @include button--variant(plain, '&') {
        color: $sim-c-warning-500;

        @include button--hover('&') {
            background-color: rgba($sim-c-warning-500, 0.1);
        }
    }
}

@include button--color('danger') {
    @include button--variant(solid, '&') {
        background-color: $sim-c-danger-500;
        color: $sim-c-white;

        @include button--hover('&') {
            background-color: $sim-c-danger-600;
        }
    }

    @include button--variant(outlined, '&') {
        background-color: $sim-c-white;
        color: $sim-c-danger-500;

        --outline-color: #{$sim-c-danger-500};

        @include button--hover('&') {
            background-color: $sim-c-danger-500;
            color: $sim-c-white;
        }
    }

    @include button--variant(plain, '&') {
        color: $sim-c-danger-500;

        @include button--hover('&') {
            background-color: rgba($sim-c-danger-500, 0.1);
        }
    }
}

@include button--color('purple') {
    @include button--variant(solid, '&') {
        background-color: $sim-c-primary-600;
        color: $sim-c-white;

        @include button--hover('&') {
            background-color: $sim-c-primary-400;
            color: $sim-c-primary-800;
        }
    }

    @include button--variant(outlined, '&') {
        background-color: $sim-c-white;
        color: $sim-c-primary-600;

        --outline-color: #{$sim-c-primary-600};

        @include button--hover('&') {
            background-color: $sim-c-primary-600;
            color: $sim-c-white;
        }
    }

    @include button--variant(plain, '&') {
        color: $sim-c-primary-600;

        @include button--hover('&') {
            background-color: rgba($sim-c-primary-400, 0.2);
        }
    }
}

@include button--color('simplo') {
    @include button--variant(solid, '&') {
        background-color: $sim-c-simplo-darker;
        color: $sim-c-white;

        @include button--hover('&') {
            background-color: $sim-c-simplo-darkest;
        }
    }

    @include button--variant(outlined, '&') {
        background-color: $sim-c-white;
        color: $sim-c-simplo-darker;

        --outline-color: #{$sim-c-simplo-darker};

        @include button--hover('&') {
            background-color: $sim-c-simplo-darker;
            color: $sim-c-white;
        }
    }

    @include button--variant(plain, '&') {
        color: $sim-c-simplo-darker;

        @include button--hover('&') {
            background-color: rgba($sim-c-simplo-darker, 0.1);
        }
    }
}


</style>
