import { ApiService, type ApiServiceFetchOptions } from '@composable-api/service/api.service'
import type { ApiModel } from '@composable-api/api.model'

class SessionRefreshApiService<M extends ApiModel> extends ApiService<M> {
    post(options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'POST',
            refreshOnExpiredSession: false,
        })
    }
}

export function getSessionRefreshApiService() {
    return new SessionRefreshApiService({
        endpoint: '/auth/refresh',
        routePrefix: '/api',
        relativeUrl: true,

    }, CustomerSessionModel)
}
