<template>
    <NuxtLayout name="c-root" :layout-props="{ navbarType: 'minimal' }">
        <NuxtLayout name="bare">
            <CoreContainer>
                <BaseContainerContent class="flex flex-col items-center" :padding="{ vertical: 'large' }">

                    <!--  ERROR HEADING BLOCK  -->
                    <div class="relative flex w-full flex-col items-center">
                        <!-- STATUS CODE  -->
                        <h1 class="sim-error-code">
                            {{ error.statusCode }}
                        </h1>
                        <!--  HEADING  -->
                        <h2 class="sim-h1 sim-error-heading">
                            {{ translationKeys ? $t(translationKeys.heading) : error.statusMessage || error.message }}
                        </h2>

                        <DevOnly v-if="error.statusCode !== 404">
                            <!--  eslint-disable-next-line  -->
                            <pre class="max-w-2xl overflow-scroll w-full p-3">{{ error.stack }}</pre>
                        </DevOnly>
                    </div>


                    <!--  404 Not Found content  -->
                    <template v-if="error.statusCode === 404">
                        <!--  SUBTITLE  -->
                        <i18n-t
                            keypath="_core_theme.pages.error.not_found.text"
                            tag="p"
                            class="sim-error-text"
                            scope="global"
                        >
                            <template #homepage_link>
                                <BaseUiButton
                                    content-class="sim-link"
                                    variant="bare"
                                    size="none"
                                    @click="handleError"
                                >
                                    {{ $t('_core_theme.pages.error.not_found.homepage_link') }}
                                </BaseUiButton>
                            </template>
                        </i18n-t>

                        <!--  SEARCH  -->
                        <MainSearchComponent
                            size="normal"
                            class="mt-20"
                        />
                    </template>

                </BaseContainerContent>
            </CoreContainer>
        </NuxtLayout>
    </NuxtLayout>
</template>

<script lang="ts" setup>
import type { NuxtError } from '#app'

const {
    error,
} = defineProps<{
    error: NuxtError
}>()

const localePath = useLocalePath()

const translationKeys = computed(() => {
    switch (error.statusCode) {
        case 404:
            return {
                heading: '_core_theme.pages.error.not_found.heading',
                text: '_core_theme.pages.error.not_found.text',
            }
    }
    return null
})

function handleError() {
    clearError({ redirect: localePath('/') })
}

</script>

<style lang="scss" scoped>

.sim-error-code {
    text-align: center;
    font-size: clamp(6rem, 40vw, 19.5rem);
    font-weight: 700;
    letter-spacing: 0.01em;
    color: $sim-c-basic-400;
    opacity: 30%;
    user-select: none;

    @include more-than(lg) {
        font-size: 19.5rem;
    }
}

.sim-error-heading {
    text-align: center;
    max-width: 35ch;
    text-wrap: balance;

    @include more-than(lg) {
        transform: translateY(-1.5em);
        margin-bottom: -1em;
    }
}

.sim-error-text {
    text-align: center;
    z-index: 1;

    @include sim-text-subtitle;
    @include sim-text-normal;
}

</style>
