import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [ProductRelatedProductModel.ATTR_ID]: number
    [ProductRelatedProductModel.ATTR_MAIN_PRODUCT_ID]: number
    [ProductRelatedProductModel.ATTR_RELATED_PRODUCT_ID]: number
    [ProductRelatedProductModel.ATTR_RELATION_TYPE]: string
    [ProductRelatedProductModel.ATTR_POSITION]: number
}

interface Embeds {
    [ProductRelatedProductModel.EMBED_RELATED_PRODUCT_NAME]?: string | null
}

export class ProductRelatedProductModel extends ApiModel<Attributes, Embeds> {
    static readonly ATTR_ID = 'id'
    static readonly ATTR_MAIN_PRODUCT_ID = 'main_product_id'
    static readonly ATTR_RELATED_PRODUCT_ID = 'related_product_id'
    static readonly ATTR_RELATION_TYPE = 'relation_type'
    static readonly ATTR_POSITION = 'position'

    static readonly EMBED_RELATED_PRODUCT_NAME = 'related_product_name'

    get id() {
        return this._getAttribute(ProductRelatedProductModel.ATTR_ID)
    }

    get mainProductId() {
        return this._getAttribute(ProductRelatedProductModel.ATTR_MAIN_PRODUCT_ID)
    }

    get relatedProductId() {
        return this._getAttribute(ProductRelatedProductModel.ATTR_RELATED_PRODUCT_ID)
    }

    get relationType() {
        return this._getAttribute(ProductRelatedProductModel.ATTR_RELATION_TYPE)
    }

    get position() {
        return this._getAttribute(ProductRelatedProductModel.ATTR_POSITION)
    }

    get relatedProductName() {
        return this._getEmbed(ProductRelatedProductModel.EMBED_RELATED_PRODUCT_NAME)
    }

    // ---------------------------------------------------------------------------------------------------------------------

}

// =====================================================================================================================
// TYPESCRIPT TYPE DECLARATIONS
// =====================================================================================================================

