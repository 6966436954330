import { performanceCheckpoint } from '@core-theme/app/utils/performance'

export default defineNuxtPlugin({
    name: 'properties',
    parallel: true,
    setup: async (nuxtApp) => {
        // this plugin initializes a pinia store so there's no need to
        // call it again on the client
        if (import.meta.client) return

        performanceCheckpoint(useRequestEvent()!, 'shop-info')

        const propertiesStore = usePropertiesStore()

        const shopInfoService = useShopInfoApiService()
        const countriesService = useCountriesApiService()
        const currenciesService = useCurrenciesApiService()
        const freeShippingService = useFreeShippingApiService()

        await Promise.all([
            // ROUTES, HOSTNAMES
            shopInfoService.embed([
                ShopInfoModel.EMBED_HOSTNAMES,
                ShopInfoModel.EMBED_ROUTES,
            ]).useGet({
                watch: false,
                ssrCache: 'shop-info',
                onResponse: (response) => {
                    propertiesStore.shopInfo = response.getItem()
                },
            }),
            // COUNTRIES
            countriesService.useGet({
                watch: false,
                ssrCache: 'shop-info-countries',
                onResponse: (response) => {
                    propertiesStore.countries = response.getItems()
                },
            }),
            // CURRENCIES
            currenciesService.useGet({
                watch: false,
                ssrCache: 'shop-info-currencies',
                onResponse: (response) => {
                    propertiesStore.currencies = response.getItems()
                },
            }),
            // FREE SHIPPING
            freeShippingService.useGet({
                watch: false,
                ssrCache: 'free-shipping',
                onResponse: (response) => {
                    propertiesStore.freeShipping = response.getItem()
                },
            }),
        ])

        performanceCheckpoint(useRequestEvent()!, 'shop-info')
    },
})
