import { useNuxtApp } from '#app'
import { ApiLanguageCodesMap, type GetTranslationValue, type Translation } from '../types/general-data'

export function useLocalization() {
    const nuxtApp = useNuxtApp()

    function getLocalizedValue<T extends Translation<any, true>>(val: T): GetTranslationValue<T> | null {
        // TODO: do not rely on the i18n plugin, use composable-api module
        // @ts-ignore
        const localeKey = ApiLanguageCodesMap[nuxtApp.$i18n!.locale.value as keyof typeof ApiLanguageCodesMap] ?? null

        if (typeof val !== 'object') return val as GetTranslationValue<T>
        return val?.[localeKey as keyof typeof val] as unknown as GetTranslationValue<T> ?? null
    }

    return {
        getLocalizedValue,
    }
}
