import { type ConstructorType } from '../types/utils'
import { ApiModel, type ApiModelKey, type ApiModelReducedData } from '../api/api.model'
import { ApiResponse, type ApiResponseReducedData } from '../api/api.response'
import { errorLog } from './logging'

export function createModelKey(modelClass: ConstructorType<ApiModel> | null): ApiModelKey | null {
    if (!modelClass) return null
    return modelClass.name.split(/(?=[A-Z])/).join('_').toLowerCase()
}

export function createClassesMap(models: ConstructorType<ApiModel>[]): Record<ApiModelKey, ConstructorType<ApiModel>> {
    return models.reduce((modelClasses: Record<ApiModelKey, ConstructorType<ApiModel>>, model) => {
        const modelKey = createModelKey(model)
        if (!modelKey) throw new Error(`Model class name '${model}' is not valid. Have you registered the correct class in the ApiResponse payload plugin?`)
        modelClasses[modelKey] = model
        return modelClasses
    }, {})
}

export function getApiResponsePayloadReducer() {
    return (data: any) => {
        return data instanceof ApiResponse && data.getReducedData()
    }
}

export function getApiResponsePayloadReviver(models: Record<ApiModelKey, ConstructorType<ApiModel>>) {
    return (data: ApiResponseReducedData) => {
        const model = data[1] ? models[data[1]] : null
        if (!model && model !== null) throw new Error(`Unknown model class: '${data[1]}'. Have you registered it in the model map for serialization?`)

        return new ApiResponse(data[0], model, data[2])
    }
}

export function getApiModelPayloadReducer() {
    return (data: any) => {
        // @ts-expect-error accessing private method of ApiModel
        return data instanceof ApiModel && data.getReducedData()
    }
}

export function getApiModelPayloadReviver(models: Record<ApiModelKey, ConstructorType<ApiModel>>) {
    return (data: ApiModelReducedData<any, any>) => {
        const modelKey = data[1]
        if (!modelKey) {
            errorLog(data)
            throw new Error('No model key was provided when reviving.')
        }

        const model: ConstructorType<ApiModel> | null = models[modelKey] ?? null
        if (!model) throw new Error(`Unknown model class: '${modelKey}'. Have you registered it in the model map for serialization?`)

        return new model(data[0])
    }
}
