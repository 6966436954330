import { useState } from '#app'
import { errorLog } from '../utils/logging'

type ApiDataRefreshCallback = {
    /**
     * The type of the context where the data is fetched.
     * - `component` - The data is fetched in a component.
     * - `scope` - The data is fetched in another effect scope.
     */
    type: 'component' | 'scope'
    refresh: () => Promise<void>
}

function useStateApiDataRefreshCallbacks() {
    return useState<ApiDataRefreshCallback[]>(() => [])
}

export function useApiDataRefreshCallbacks() {
    const state = useStateApiDataRefreshCallbacks()
    return {
        hook: (callback: ApiDataRefreshCallback) => {
            state.value.push(callback)

            return () => {
                const index = state.value.findIndex(entry => entry.refresh === callback.refresh)
                if (index > -1) {
                    state.value.splice(index, 1)
                } else {
                    errorLog('[useApiDataRefreshCallbacks]: Callback not found when unhooking.')
                }
            }
        },
    }
}


export function useRefreshApiData() {
    const state = useStateApiDataRefreshCallbacks()

    /**
     * Refresh all data that is fetched in the current context.
     * @param type The type of the context where the data is fetched. Defaults to `component`.
     */
    async function refreshData(type?: ApiDataRefreshCallback['type']) {
        // get the callbacks that match the type or only the `'component'` ones
        const callbacks = state.value.filter(entry => type ? entry.type === type : entry.type === 'component')
        await Promise.all(callbacks.map(callback => callback.refresh()))
    }

    return refreshData
}
