import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin({
    name: 'sentry',
    parallel: true,
    setup: (nuxtApp) => {
        const appConfig = useAppConfig()
        // @ts-ignore
        if (!appConfig.plugins?.sentry?.enabled) return

        onNuxtReady(() => {
            const runtimeConfig = useRuntimeConfig()
            const router = useRouter()

            Sentry.init({
                app: nuxtApp.vueApp,
                dsn: runtimeConfig.public.sentryDsn,
                integrations: [
                    Sentry.browserTracingIntegration({ router }),
                    Sentry.replayIntegration(),
                ],
                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                // We recommend adjusting this value in production
                tracesSampleRate: 1.0,

                // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ['*'],

                // Capture Replay for 0% of all sessions,
                // plus for 100% of sessions with an error
                replaysSessionSampleRate: 0,
                replaysOnErrorSampleRate: 1.0,
            })
        })
    },
})
