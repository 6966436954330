import {
    ApiReactiveService,
    type ApiServiceUseFetchOptions
} from '@composable-api/service/api.reactive-service'
import { type ApiServiceFetchOptions } from '@composable-api/service/api.service'
import type { ApiModel, ApiModelAttributes } from '@composable-api/api.model'
import { AddressModel } from '../models/address.model'
import { CartAddressModel } from '../models/cart-address.model'
import { type MaybeRefOrGetter, toValue } from 'vue'

class CartAddressesApiReactiveService<M extends ApiModel> extends ApiReactiveService<M> {
    useGet<T extends boolean>(options?: ApiServiceUseFetchOptions<M, T>) {
        return this.useFetch({
            ...options,
            method: 'GET',
        })
    }

    patchBilling(data: Partial<ApiModelAttributes<AddressModel>> | CustomerAddressPayload, options?: ApiServiceFetchOptions<M>) {
        return this
            .addRouteParam('billing')
            .fetch({
                ...options,
                method: 'PATCH',
                body: data,
            })
    }

    patchShipping(data: Partial<ApiModelAttributes<AddressModel>> | CustomerAddressPayload, options?: ApiServiceFetchOptions<M>) {
        return this
            .addRouteParam('shipping')
            .fetch({
                ...options,
                method: 'PATCH',
                body: data,
            })
    }
}

export function useCartAddressesApiService(options: {
    cartId: MaybeRefOrGetter<string | null | undefined>
}) {
    return new CartAddressesApiReactiveService(
        {
            endpoint: () => `/carts/${toValue(options.cartId)}/addresses`,
        },
        CartAddressModel
    )
}

export interface CustomerAddressPayload {
    customer_address_id: number
}
