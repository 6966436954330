import type { RouteLocationNormalized } from 'vue-router'

// TODO: add app.config.ts overrides per theme, if needed
const themePaths = {
    login: 'login',
}

type ThemePath = keyof typeof themePaths

export function useThemePaths(route: RouteLocationNormalized | null = null) {
    const localePath = useLocalePath()
    const _route = route ?? useRoute()

    /**
     * A function that checks whether the current route or the given path is the given theme path.
     * @example
     * // check if the current route is the login page
     * const isLoginPage = isThemePath('login')
     *
     * // check if the given path is the login page (in middleware)
     * const isLoginPageInMiddleware = isThemePath('login', to.path)
     *
     * @todo add info about path route parameter, rename param
     * @param themePath the theme path name to check against
     * @param path the path to compare the theme path with (if not provided, the current route will be used)
     */
    function isThemePath(themePath: ThemePath, path: string | RouteLocationNormalized | null = null) {
        const isRoute = typeof path !== 'string' && path !== null
        return localePath(themePaths[themePath]) === ((isRoute ? path.path : path) ?? _route.path)
    }

    /**
     * A function to get the string path of a theme path.
     * Automatically localizes the path.
     * @todo add info about route parameter
     * @param themePath the theme path name to get the path for
     */
    function getThemePath(themePath: ThemePath) {
        return localePath(themePaths[themePath])
    }

    return {
        isThemePath,
        getThemePath,
    }
}
