import type { SlotOverride } from '@core-types/components'
import type { Slot, VNode } from 'vue'

/**
 * Get the attribute with the scope id of the component
 * so that scoped styles apply on the child component's elements correctly.
 *
 * Useful for <Teleport>ed content, for example.
 */
export function getScopeIdAttr() {
    const scopeId = getCurrentInstance()?.vnode.scopeId
    return scopeId
        ? {
            [scopeId]: '',
        }
        : {}
}

/**
 * Get the attribute with the scope id of the component
 * so that scoped styles apply on the child component's elements correctly.
 *
 * Useful for <Teleport>ed content, for example.
 */
export function getParentScopeIdAttr() {
    const scopeId = getCurrentInstance()?.parent?.vnode.scopeId
    return scopeId
        ? {
            [scopeId]: '',
        }
        : {}
}

export function getSlotScopeIdAttr() {
    // @ts-ignore
    const scopeId = getCurrentInstance()?.vnode?.type?.__scopeId
    return scopeId
        ? {
            [scopeId]: '',
        }
        : {}
}

/**
 * Normalize the return type of slot override.
 * Handles the case when the slot override doesn't return anything or returns a single VNode.
 *
 * The normalized value is safe to use as children of a VNode.
 * @param slotOverride The return value of the slot override.
 */
export function normalizeSlotValue(slotOverride: ReturnType<SlotOverride<any>>) {
    if (!slotOverride) return null
    return Array.isArray(slotOverride) ? slotOverride : [slotOverride]
}

/**
 * A utility function that is meant to be used in tsx to either render a slot or its default fallback content.
 * It is also possible to override the default slot content so that it can be specified for each component
 * defined via `defineComponent`
 * @param templateSlot the slot that will be provided when using the component through `<template>`
 * @param slotOverride the override for default slot data (when defining through custom `defineComponent` call)
 * @param slotData the data for the slot (refs, etc.)
 * @param fallback the default value for the slot (to be provided in the base component)
 */
export function renderSlot<D extends Slot, T extends SlotOverride<any>>(templateSlot: D | undefined, slotOverride: T | undefined, slotData: Parameters<D>[0], fallback: VNode | VNode[] | (() => string | null) | undefined = undefined): VNode[] | VNode | (() => string | null) | null {
    // the slot provided through <template>
    if (templateSlot) {
        return templateSlot(slotData)
    }

    // the overridden slot through custom defineComponent slotOverrides
    if (slotOverride) {
        return normalizeSlotValue(slotOverride(slotData))
    }

    // return fallback content
    return fallback ?? null
}
