import { ApiModel } from '@composable-api/api.model'
import type { ApiDateTime } from '../types/general-data'

interface Attributes {
    [CountryModel.ATTR_ID]: string
    [CountryModel.ATTR_NAME]: string
    [CountryModel.ATTR_DIAL_CODE]: string | null
    [CountryModel.ATTR_NUMERIC_CODE]: number | null
    [CountryModel.ATTR_ALPHA2]: string | null
    /**
     * The country name in the native language.
     */
    [CountryModel.ATTR_ORIGINAL_NAME]: string | null
    [CountryModel.ATTR_IS_ACTIVE]: boolean
    [CountryModel.ATTR_IS_DEFAULT]: boolean
    [CountryModel.ATTR_LANGUAGE_ID]: string | null
    [CountryModel.ATTR_CREATED_AT]: ApiDateTime


}

interface Embeds {
}

export class CountryModel extends ApiModel<Attributes, Embeds> {
    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_DIAL_CODE = 'dial_code'
    static readonly ATTR_NUMERIC_CODE = 'numeric_code'
    static readonly ATTR_ALPHA2 = 'alpha2'
    static readonly ATTR_ORIGINAL_NAME = 'original_name'
    static readonly ATTR_IS_ACTIVE = 'is_active'
    static readonly ATTR_IS_DEFAULT = 'is_default'
    static readonly ATTR_LANGUAGE_ID = 'language_id'
    static readonly ATTR_CREATED_AT = 'created_at'

    get id() {
        return this._getAttribute(CountryModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(CountryModel.ATTR_NAME)
    }

    get dialCode() {
        return this._getAttribute(CountryModel.ATTR_DIAL_CODE)
    }

    get numericCode() {
        return this._getAttribute(CountryModel.ATTR_NUMERIC_CODE)
    }

    get alpha2() {
        return this._getAttribute(CountryModel.ATTR_ALPHA2)
    }

    get originalName() {
        return this._getAttribute(CountryModel.ATTR_ORIGINAL_NAME)
    }

    get isActive() {
        return this._getAttribute(CountryModel.ATTR_IS_ACTIVE)
    }

    get isDefault() {
        return this._getAttribute(CountryModel.ATTR_IS_DEFAULT)
    }

    get languageId() {
        return this._getAttribute(CountryModel.ATTR_LANGUAGE_ID)
    }

    get createdAt() {
        return this._getAttribute(CountryModel.ATTR_CREATED_AT)
    }
}
