import { ApiService, type ApiServiceFetchOptions } from '@composable-api/service/api.service'
import type { ApiModel } from '@composable-api/api.model'
import { PageModel } from '../models/page.model'

class PageApiService<M extends ApiModel> extends ApiService<M> {
    get(options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'GET',
        })
    }
}

export function getPageApiService() {
    return new PageApiService({
        endpoint: 'page',
    }, PageModel)
}
