<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.9697 17.394C17.2626 17.1011 17.7374 17.1011 18.0303 17.394L21.0303 20.394C21.3232 20.6869 21.3232 21.1618 21.0303 21.4547C20.7374 21.7476 20.2626 21.7476 19.9697 21.4547L16.9697 18.4547C16.6768 18.1618 16.6768 17.6869 16.9697 17.394Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-linecap="round"
        />
        <path fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.473 10.7365C18.473 15.0092 15.0092 18.473 10.7365 18.473C6.46375 18.473 3 15.0092 3 10.7365C3 6.46375 6.46375 3 10.7365 3C15.0092 3 18.473 6.46375 18.473 10.7365ZM10.7365 16.6038C13.9769 16.6038 16.6038 13.9769 16.6038 10.7365C16.6038 7.49608 13.9769 4.8692 10.7365 4.8692C7.49608 4.8692 4.8692 7.49608 4.8692 10.7365C4.8692 13.9769 7.49608 16.6038 10.7365 16.6038Z"
            fill="currentColor"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
