import { ApiModel } from '@composable-api/api.model'

export interface ProductFilterItemAttributes {
    [ProductFilterItemModel.ATTR_ID]: string
    [ProductFilterItemModel.ATTR_URL]: string
    [ProductFilterItemModel.ATTR_VALUE]: string | null
    [ProductFilterItemModel.ATTR_LABEL]: string
    [ProductFilterItemModel.ATTR_COUNT]: number
    [ProductFilterItemModel.ATTR_IS_SELECTED]: boolean
    [ProductFilterItemModel.ATTR_OPTIONS]: {
        image: {
            id: number | null
            mime_type: string | null
            url: string | null
        }
    }
}

interface Embeds {
}

export class ProductFilterItemModel extends ApiModel<ProductFilterItemAttributes, Embeds> {
    static readonly ATTR_ID = 'id'
    static readonly ATTR_URL = 'url'
    static readonly ATTR_VALUE = 'value'
    static readonly ATTR_LABEL = 'label'
    static readonly ATTR_COUNT = 'count'
    static readonly ATTR_IS_SELECTED = 'is_selected'
    static readonly ATTR_OPTIONS = 'options'

    get id() {
        return this._getAttribute(ProductFilterItemModel.ATTR_ID)
    }

    get url() {
        return this._getAttribute(ProductFilterItemModel.ATTR_URL)
    }

    get value() {
        return this._getAttribute(ProductFilterItemModel.ATTR_VALUE)
    }

    get label() {
        return this._getAttribute(ProductFilterItemModel.ATTR_LABEL)
    }

    get count() {
        return this._getAttribute(ProductFilterItemModel.ATTR_COUNT)
    }

    get isSelected() {
        return this._getAttribute(ProductFilterItemModel.ATTR_IS_SELECTED)
    }

    get options() {
        return this._getAttribute(ProductFilterItemModel.ATTR_OPTIONS)
    }

    // --------------------------------------------------

    shouldBeVisible() {
        return (this.count ?? 0) > 0 || this.isSelected
    }

    getThumbnailUrl() {
        return this.options?.image?.url ?? null
    }
}
