import { defineStore } from 'pinia'
import { CustomerAuthFormType } from '../../types/components'

export const useModalsStore = defineStore('modals', () => {
    const { is: isAuthModalOpen, was: wasAuthModalMounted } = useLazyMount()
    const authModalType = ref<CustomerAuthFormType>(CustomerAuthFormType.LOGIN)

    return {
        isAuthModalOpen,
        wasAuthModalMounted,
        authModalType,
    }
})
