import { type FetchResponse } from 'ofetch'

interface ApiErrorData {
    id: string
    message: string
    type: 'Unauthenticated' | 'ValidationError'
    errors?: ApiFieldError[]   // Present when the response status is 422 (Unprocessable Entity)
}

interface ApiFieldError {
    field: string   // Name of the input field
    message: string // Human-readable description of the problem
}

export class ApiResponseError {
    private readonly validationErrors: ApiValidationError[]
    private readonly data: {
        status: number
        _data: ApiErrorData | null | undefined
    }

    constructor(response: FetchResponse<ApiErrorData | null>) {
        this.data = {
            status: response.status,
            _data: response._data,
        }
        this.validationErrors = []
    }

    getReducedData() {
        return this.data
    }

    getErrorMessage() {
        return this.data._data?.message ?? null
    }

    /**
     * Returns the HTTP status code of the API response.
     */
    getStatus() {
        return this.data.status
    }

    /**
     * Checks whether the HTTP status code of the API response is equal to the provided code.
     * @param status the status code to compare
     */
    isStatus(status: number) {
        return this.getStatus() === status
    }

    getType() {
        return this.data._data?.type ?? null
    }

    private get errors() {
        return this.data._data?.errors ?? []
    }

    /**
     * Instantiates the `ValidationError` objects upon first call.
     * Returns an array of `ValidationError` object instances. If no validation errors occurred,
     * an empty array is returned.
     */
    getValidationErrors() {
        // create instances of ValidationError for each error if not already created
        if (!this.validationErrors.length && this.errors) {
            for (const error of this.errors) {
                this.validationErrors.push(new ApiValidationError(error))
            }
        }
        return this.validationErrors
    }

    /**
     * Returns an instance of the first `ValidationError`. If no validation errors occurred,
     * the object getters return `null`.
     */
    getFirstValidationError(): ApiValidationError {
        return this.getValidationErrors()[0] ?? new ApiValidationError(null)
    }

    /**
     * Returns an object with validation errors where the keys are the field names and the values are the error messages.
     */
    getValidationErrorsByField() {
        const errorsByField: { [field: string]: ApiValidationError['message'] } = {}
        for (const error of this.getValidationErrors()) {
            errorsByField[error.field!] = error.message
        }
        return errorsByField
    }
}

/**
 * A field validation error - used when the server response status code is 422.
 * An instance of this class is used in the `ApiResponseError` object that is returned
 * when there is an API error.<br>
 * This object shouldn't be used directly.
 */
class ApiValidationError {
    constructor(private error: ApiFieldError | null) {
        this.error = error
    }

    get field() {
        return this.error?.field ?? null
    }

    get message() {
        return this.error?.message ?? null
    }
}
