<template>
    <svg xmlns="http://www.w3.org/2000/svg"
         class="sim-loading-spinner"
         :width="currentPreset.size"
         :height="currentPreset.size"
         viewBox="0 0 14 14"
    >
        <circle
            class="sim-loading-spinner__circle"
            cx="7"
            cy="7"
            r="5"
            :stroke-width="currentPreset.strokeWidth"
            fill="none"
        />
    </svg>
</template>

<script lang="ts" setup>

const {
    extraSmall,
    small,
    medium,
} = defineProps<{
    extraSmall?: boolean
    small?: boolean
    medium?: boolean
}>()


const currentPreset = computed<Preset>(() => {
    if (extraSmall) {
        return {
            size: '1.2rem',
            strokeWidth: '1.5',
        }
    }

    if (small) {
        return {
            size: '2rem',
            strokeWidth: '2',
        }
    }

    if (medium) {
        return {
            size: '4rem',
            strokeWidth: '1.75',
        }
    }

    return {
        size: '4rem',
        strokeWidth: '1.75',
    }
})


// TYPESCRIPT TYPE DEFINITIONS

interface Preset {
    size: string
    strokeWidth: string
}


</script>

<style lang="scss" scoped>

.sim-loading-spinner__circle {
    stroke: currentcolor;
    stroke-linecap: round;
    stroke-dasharray: 24;
    animation: spin 1.5s linear infinite, dash 1.5s linear infinite reverse;
    transform-origin: center;

    will-change: transform, stroke-dashoffset;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    from {
        stroke-dashoffset: 0;
    }

    to {
        stroke-dashoffset: 48;
    }
}

</style>
