import { CategoryModel } from './category.model'

interface Attributes {
    [CategoryTreeModel.ATTR_CHILDREN]: CategoryTreeModel[]
}

export class CategoryTreeModel extends CategoryModel<Attributes> {
    static readonly ATTR_CHILDREN = '_children'

    get children() {
        return this._getAttribute(CategoryTreeModel.ATTR_CHILDREN, CategoryTreeModel)
    }
}
