import { CartModel } from '@simploshop-models/cart.model'
import { CartItemModel } from '@simploshop-models/cart-item.model'

export const CART_EMBEDS = [
    CartModel.EMBED_SHIPPING_OPTION,
] as const

export const CART_ITEMS_EMBEDS = [
    CartItemModel.EMBED_PRODUCT_AVAILABILITY,
    CartItemModel.EMBED_PRODUCT_AMOUNT_UNIT_TRANSLATIONS,
    CartItemModel.EMBED_IMAGE_URL,
    CartItemModel.EMBED_VARIATION_PROPERTIES_NAMES, // selected attributes for properties (product variations)
] as const

