import type { BreadcrumbItem } from '@core/types/components/CoreUiBreadcrumbs'
import type { ComputedRef, MaybeRefOrGetter } from 'vue'

interface UseBreadcrumbsOptions {

}

/**
 * A composable that gets the breadcrumbs generated for the current Nuxt only route.
 * This should be only used in case there is no page API response.
 * For pages with a page API response use the `useCurrentPage` composable instead. (access the breadcrumbs
 * on the PageModel)
 *
 * @see useCurrentPage
 */
export function useBreadcrumbs(options?: MaybeRefOrGetter<Partial<UseBreadcrumbsOptions>>) {
    const route = useRoute()
    const { t } = useI18n()

    let breadcrumbItems: ComputedRef<BreadcrumbItem[]>

    return {
        get items() {
            if (!breadcrumbItems) {
                if (!getCurrentInstance()) {
                    throw new Error('The `useBreadcrumbs()` composable must be called at the top level of ' +
                    'the script setup block of a component when accessing the `items` property.')
                }

                const computeBreadcrumbs = () => {
                    _breadcrumbItems.value = route.matched
                        .filter(route => !!route.meta.breadcrumb /* filter to only routes which have a breadcrumb name */)
                        .map((route) => {
                            const label = typeof route.meta.breadcrumb === 'string' ? route.meta.breadcrumb : route.meta.breadcrumb!.path
                            const params = typeof route.meta.breadcrumb === 'string' ? undefined : route.meta.breadcrumb!.params()

                            return {
                                label: params ? t(label, params as any) : t(label),
                                to: { path: route.path },
                            } satisfies BreadcrumbItem
                        })
                }

                const _breadcrumbItems = ref<BreadcrumbItem[]>([])
                breadcrumbItems = computed(() => _breadcrumbItems.value)

                // compute the breadcrumbs for first load
                computeBreadcrumbs()

                useOnPageLoaded(computeBreadcrumbs, { once: false }) // is called in a component context, should unhook on unmount


            }

            return breadcrumbItems
        },
    }
}

/**
 * useState() wrapper for a Map of known pages.
 * The value is either `PageModel` or `null` if the page is not a backend page.
 * Storing `null` is important to prevent unnecessary API requests and remember that the page is not a backend page.
 *
 * ### This should only be used internally. Do not use in themes directly.
 * @private
 */
export function useStatePage() {
    return useState<Map<string, InstanceType<typeof PageModel> | null>>(() => new Map<string, InstanceType<typeof PageModel> | null>())
}

/**
 * A composable used to get the data about the current page from the API.
 * Including the page title, meta tags, structured data, page type, links, etc.
 *
 * **WARNING** - the data is changed immediately after the navigation starts,
 * not after the page is fully loaded. This might cause issues if not taken
 * into account with pages that use top-level async data.
 *
 * To have the data of the current page after the navigation is fully loaded,
 * use the `syncedPage` property of the returned object instead of the `page` property.
 */
export function useCurrentPage() {
    const router = useRouter()
    const pages = useStatePage()

    const page = computed(() => pages.value.get(router.currentRoute.value.path) ?? null)
    const syncedPage = ref<InstanceType<typeof PageModel> | null>(null)
    let hasSyncBeenAccessed = false

    /*
     * Intercept the access to the synced page to initialize the hook
     * only if the synced page is accessed to prevent unnecessary nuxt hook callbacks.
     */
    const syncedPageProxy = new Proxy(syncedPage, {
        get(target, prop, receiver) {
            // initialize the hook only when the synced page is accessed
            if (!hasSyncBeenAccessed) {

                if (!getCurrentInstance()) {
                    throw new Error('The `useCurrentPage()` composable must be called at the top level of ' +
                        'the script setup block of a component when accessing the `syncedPage` property.')
                }

                useOnPageLoaded(() => {
                    const page = pages.value.get(router.currentRoute.value.path)
                    if (page) {
                        syncedPage.value = page
                    }
                }, { once: false })

                // set the initial value for the synced page
                syncedPage.value = page.value

                hasSyncBeenAccessed = true
            }

            return Reflect.get(target, prop, receiver)
        },
    })

    return {
        page: page,
        syncedPage: syncedPageProxy,
    }
}
