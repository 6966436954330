import { type PageTypeName } from '@simploshop-models/page.model'
import type { ObjectPlugin } from 'nuxt/app'
import type { RouteRecordRedirect } from 'vue-router'
import { sendRedirect } from 'h3'

export function getRoutingPlugin(getImportFn: (pageName: PageTypeName) => (() => Promise<any>) | null): ObjectPlugin<Record<string, unknown>> {
    return {
        name: 'routing',
        parallel: true,
        setup: (nuxtApp) => {
            const router = useRouter()

            const { fetchPage } = useInit()

            // the currently known dynamic routes
            const pages = useStatePage()

            const authStore = useAuthStore()

            const event = useRequestEvent()

            // ROUTER RULE - BEFORE EACH NAVIGATION
            router.beforeEach(async (to) => {
                const isRouteInRouter = router.getRoutes().some(route => route.path === to.path)

                const page = await fetchPage(to)

                // if the page is not a backend page, continue to Nuxt routing
                if (page === null) return

                // add the route to the router if it's not already there
                let route = null
                if (!isRouteInRouter) {
                    // handle redirect pages
                    if (page.kind === 'redirect') {
                        const redirectUrl = page.destination
                        const statusCode = page.statusCode

                        if (!redirectUrl || !statusCode) {
                            console.error('Redirect page is missing destination or status code', page._toJSON())
                            return
                        }

                        const route = {
                            path: to.path,
                            redirect: redirectUrl,
                        } satisfies RouteRecordRedirect

                        router.addRoute(route)

                        if (event) {
                            return sendRedirect(event, redirectUrl, statusCode)
                        }

                        return { path: route.path, query: to.query }
                    }


                    const pageTypeName = page?.getPageType()

                    // if the page type is not known, continue to Nuxt routing
                    if (!pageTypeName) return   // should go to 404 or something

                    // get the function for dynamic import
                    const importFn = getImportFn(pageTypeName)
                    if (!importFn) return

                    route = {
                        path: to.path,
                        name: `dynamic${to.path.replace(/\//g, '-')}`,
                        component: importFn,
                    }
                    router.addRoute(route)
                }

                // run the routing plugin again to redirect to the newly added route
                return route ? { name: route.name, query: to.query } : undefined
            })
        },
    }
}
