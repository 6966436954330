<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 18">
        <path
            fill="currentColor"
            d="M.89.12a.547.547 0 0 0-.773.095.573.573 0 0 0 .093.79l20.9 16.876a.543.543 0 0 0 .773-.092.573.573 0 0 0-.09-.79L.89.118Zm19.924 9.313a1.14 1.14 0 0 0 0-.865c-.512-1.255-1.588-3.083-3.197-4.61C16.001 2.42 13.777 1.126 11 1.126c-1.76 0-3.3.52-4.603 1.294l.939.756A7.775 7.775 0 0 1 11 2.25c2.42 0 4.39 1.125 5.871 2.531 1.482 1.407 2.472 3.094 2.929 4.22-.316.776-.89 1.827-1.702 2.864l.863.697c.88-1.125 1.502-2.264 1.853-3.13ZM3.039 5.439c-.88 1.125-1.502 2.264-1.853 3.129a1.142 1.142 0 0 0 0 .865c.512 1.255 1.588 3.083 3.197 4.609 1.615 1.54 3.84 2.833 6.617 2.833 1.76 0 3.3-.52 4.603-1.293l-.939-.756A7.775 7.775 0 0 1 11 15.75c-2.42 0-4.39-1.125-5.871-2.531C3.647 11.813 2.657 10.125 2.2 9c.316-.777.89-1.828 1.702-2.865l-.863-.696ZM11 13.5a4.28 4.28 0 0 0 1.63-.32l-1.063-.857c-.185.031-.374.049-.567.049-1.753 0-3.19-1.4-3.293-3.168l-1.062-.858c-.031.211-.045.43-.045.65 0 2.486 1.97 4.5 4.4 4.5v.004ZM15.4 9c0-2.485-1.97-4.5-4.4-4.5a4.28 4.28 0 0 0-1.63.32l1.063.858a3.37 3.37 0 0 1 .567-.05c1.753 0 3.19 1.4 3.293 3.168l1.062.858c.031-.21.045-.429.045-.65V9Z"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
