import { ApiService, type ApiServiceFetchOptions } from '@composable-api/service/api.service'
import { PusherAuthModel } from '@simploshop-models/pusher-auth.model'

class PusherAuthApiService<M extends PusherAuthModel> extends ApiService<M> {
    post(data: { socket_id: string, channel_name: string }, options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'POST',
            body: data,
        })
    }
}

export function getPusherAuthApiService() {
    return new PusherAuthApiService({
        endpoint: '/auth/broadcasting',
    }, PusherAuthModel)
}
