<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg viewBox="2 2 20 21.5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12.736" r="8.825" fill="currentColor" />
        <path fill-rule="evenodd" clip-rule="evenodd" fill="#fff"
            d="M15.384 9.467a.532.532 0 0 1 0 .752l-6.016 6.016a.532.532 0 1 1-.752-.752l6.016-6.016a.532.532 0 0 1 .752 0Z"
        />
        <path fill-rule="evenodd" clip-rule="evenodd" fill="#fff"
            d="M15.635 16.486a.532.532 0 0 1-.752 0L8.366 9.968a.532.532 0 1 1 .752-.752l6.517 6.518a.532.532 0 0 1 0 .752Z"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
