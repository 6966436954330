import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [PusherAuthModel.ATTR_AUTH]: string
    [PusherAuthModel.ATTR_CHANNEL_DATA]?: string
    [PusherAuthModel.ATTR_SHARED_SECRET]?: string
}

export class PusherAuthModel extends ApiModel<Attributes> {
    static readonly ATTR_AUTH = 'auth'
    static readonly ATTR_CHANNEL_DATA = 'channel_data'
    static readonly ATTR_SHARED_SECRET = 'shared_secret'

    get auth() {
        return this._getAttribute(PusherAuthModel.ATTR_AUTH)
    }

    get channelData() {
        return this._getAttribute(PusherAuthModel.ATTR_CHANNEL_DATA)
    }

    get sharedSecret() {
        return this._getAttribute(PusherAuthModel.ATTR_SHARED_SECRET)
    }
}
