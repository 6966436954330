import { ApiModel } from '@composable-api/api.model'
import type { ProductFilterItemAttributes } from './product-filter-item.model'
import { ProductFilterItemModel } from './product-filter-item.model'
import { ProductFilterTemplatePlaceholder, ProductFilterType } from '../enums/product'

export interface ProductFilterModelAttributes {
    [ProductFilterModel.ATTR_ID]: string
    [ProductFilterModel.ATTR_ITEMS]: ProductFilterItemAttributes[]
    [ProductFilterModel.ATTR_LABEL]: string
    [ProductFilterModel.ATTR_TYPE]: ProductFilterType
    [ProductFilterModel.ATTR_DESCRIPTION]: string | null
    [ProductFilterModel.ATTR_IS_DISPLAYED]: boolean
    [ProductFilterModel.ATTR_CONTAINS_ACTIVE_ITEMS]: boolean
    [ProductFilterModel.ATTR_ADDITIONAL_PROPS]: {
        min: number
        max: number
        current_min: number
        current_max: number
        url_template: string
    }
}

interface Embeds {
}

export class ProductFilterModel extends ApiModel<ProductFilterModelAttributes, Embeds> {
    static readonly ATTR_ID = 'id'
    static readonly ATTR_ITEMS = 'items'
    static readonly ATTR_LABEL = 'label'
    static readonly ATTR_TYPE = 'type'
    static readonly ATTR_DESCRIPTION = 'description'
    static readonly ATTR_IS_DISPLAYED = 'is_displayed'
    static readonly ATTR_CONTAINS_ACTIVE_ITEMS = 'contains_active_items'
    static readonly ATTR_ADDITIONAL_PROPS = 'additional_props'


    get id() {
        return this._getAttribute(ProductFilterModel.ATTR_ID)
    }

    get items() {
        return this._getAttribute(ProductFilterModel.ATTR_ITEMS, ProductFilterItemModel)
    }

    get label() {
        return this._getAttribute(ProductFilterModel.ATTR_LABEL)
    }

    get type() {
        return this._getAttribute(ProductFilterModel.ATTR_TYPE)
    }

    get description() {
        return this._getAttribute(ProductFilterModel.ATTR_DESCRIPTION)
    }

    get isDisplayed() {
        return this._getAttribute(ProductFilterModel.ATTR_IS_DISPLAYED)
    }

    get containsActiveItems() {
        return this._getAttribute(ProductFilterModel.ATTR_CONTAINS_ACTIVE_ITEMS)
    }

    get additionalProps() {
        return this._getAttribute(ProductFilterModel.ATTR_ADDITIONAL_PROPS)
    }

    // ----------------------------------------
    // TYPES

    isSelectType() {
        return this.type === ProductFilterType.SELECT
    }

    isCheckboxType() {
        return this.type === ProductFilterType.CHECKBOX
    }

    isRadioType() {
        return this.type === ProductFilterType.RADIO
    }

    isColorType() {
        return this.type === ProductFilterType.COLOR
    }

    isRangeType() {
        return this.type === ProductFilterType.PRICE_SLIDER
    }

    // ----------------------------------------

    isRangeActive() {
        if (!this.isRangeType()) return false
        return this.additionalProps?.current_min !== this.additionalProps?.min || this.additionalProps?.current_max !== this.additionalProps?.max
    }

    /**
     * Get the default URL for the filter. This is used to reset the filter.
     */
    getRangeUrl(): string | null
    /**
     * Get the URL for the given range.
     * @param min minimum range value
     * @param max maximum range value
     */
    getRangeUrl(min: number, max: number): string | null
    /**
     * Get the URL for the given range.
     * @param range array of two numbers: [min, max]
     */
    getRangeUrl(range: [number, number]): string | null
    getRangeUrl(...args: [number, number] | [number[]]): string | null {
        if (import.meta.dev) {
            if (!this.isRangeType()) {
                throw new Error('[ProductFilterModel]: getRangeUrl() can be called only for range type filters')
            }
        }

        if (!this.additionalProps) return null

        let min: number
        let max: number

        if (Array.isArray(args[0])) {
            [min, max] = args[0] as [number, number]
        } else if (args.length === 2) {
            [min, max] = args as [number, number]
        } else {
            min = this.additionalProps.min
            max = this.additionalProps.max
        }

        // TODO: change min_price to min?
        return this.additionalProps?.url_template
            .replace(ProductFilterTemplatePlaceholder.MIN_PRICE, min.toString())
            .replace(ProductFilterTemplatePlaceholder.MAX_PRICE, max.toString())
    }

}
