<template>
    <component
        :is="tag"
        class="sim-x-container"
        :aria-labelledby="labelId"
    >
        <slot v-bind="{ labelId }" />
    </component>
</template>

<script lang="ts" setup>

const {
    tag = 'section',
    labelled,
} = defineProps<{
    /**
     * The HTML tag to use for the container.
     * @default 'section'
     */
    tag?: 'div' | 'section' | 'article'
    /**
     * When set to `true`, the container will generate an id and set it
     * as its `aria-labelledby` attribute.
     * The id will be passed to the data of the default slot.
     * When set to a string, the string will be used as the id.
     */
    labelled?: boolean | string
}>()

const labelId = labelled === true ? useId() : labelled ? labelled : undefined

</script>

<style lang="scss" scoped>

</style>
