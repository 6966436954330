export enum Locale {
    CS = 'cs',
    SK = 'sk',
    EN = 'en',
}

export enum LocaleISO {
    CS = 'cs-CZ',
    SK = 'sk-SK',
    EN = 'en-US',
}
