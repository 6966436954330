export default defineNuxtPlugin({
    name: 'cart',
    parallel: true,
    dependsOn: ['setup'],
    setup: (nuxtApp) => {
        // purposefully not using `await` here, not to block the hydration process
        // and cause a hydration mismatch afterward
        useCart().fetchCartIfNotLoaded({ _setOnNuxtReady: true })
    },
})
