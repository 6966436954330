import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [CustomerPasswordModel.ATTR_EMAIL]: string
    [CustomerPasswordModel.ATTR_TOKEN]: string
    [CustomerPasswordModel.ATTR_NEW_PASSWORD]: string
    [CustomerPasswordModel.ATTR_NEW_PASSWORD_CONFIRMATION]: string
}

export class CustomerPasswordModel<TAttributes = unknown> extends ApiModel<Attributes & TAttributes> {
    static readonly ATTR_EMAIL = 'email'
    static readonly ATTR_TOKEN = 'token'
    static readonly ATTR_NEW_PASSWORD = 'new_password'
    static readonly ATTR_NEW_PASSWORD_CONFIRMATION = 'new_password_confirmation'

    get email() {
        return this._getAttribute(CustomerPasswordModel.ATTR_EMAIL)
    }

    get token() {
        return this._getAttribute(CustomerPasswordModel.ATTR_TOKEN)
    }

    get newPassword() {
        return this._getAttribute(CustomerPasswordModel.ATTR_NEW_PASSWORD)
    }

    get newPasswordConfirmation() {
        return this._getAttribute(CustomerPasswordModel.ATTR_NEW_PASSWORD_CONFIRMATION)
    }
}
