<template>
    <Toaster
        :toast-options="{
            classes: {
                toast: 'sim-toast'
            }
        }"
    />
</template>

<script  lang="ts" setup>
import { Toaster } from 'vue-sonner'

const {
    textColor,
} = defineProps<{
    textColor?: Partial<{
        error: string
        success: string
    }>
}>()

const textColorError = textColor?.error ?? 'white'
const textColorSuccess = textColor?.success ?? 'black'

</script>

<style  lang="scss" scoped>

// TODO: add props for customizing the toast
:deep(.sim-toast) {
    &[data-type="error"] {
        background: #ff2468 !important;
        border: 1px solid #f20055 !important;
        color: v-bind(textColorError) !important;
    }

    &[data-type="success"] {
        //background: #00c853 !important;
        //border: 1px solid #00c853 !important;
        //color: white !important;
        color: v-bind(textColorSuccess) !important;
    }
}

</style>
