import { SocialiteModel } from '@simploshop-models/socialite.model'
import { ApiService, type ApiServiceFetchOptions } from '@composable-api/service/api.service'
import type { SocialiteTypeEnum } from '@sim-api-enums/customer'

class SocialiteApiService<M extends SocialiteModel> extends ApiService<M> {
    getRedirectUrl(data: { provider: SocialiteTypeEnum, return_url: string },options?: ApiServiceFetchOptions<M>) {
        return this
            .addRouteParam('redirect')
            .fetch({
                ...options,
                method: 'POST',
                body: data,
            })
    }
}

export function getSocialiteApiService() {
    return new SocialiteApiService({
        endpoint: '/socialite',
    }, SocialiteModel)
}
