<template>
    <NuxtLayout name="c-root">
        <NuxtLoadingIndicator color="#F90259" />
        <NuxtRouteAnnouncer />
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>

        <!--  Notifications  -->
        <CoreNotifications
            :text-color="{
                success: '#00997A', // sim-c-success-700
            }"
        />

        <!--  MODAL  -->
        <LazyCustomerAuthModal
            v-if="modalsStore.wasAuthModalMounted"
            v-model="modalsStore.isAuthModalOpen"
            v-model:type="modalsStore.authModalType"
        />
    </NuxtLayout>
</template>

<script lang="ts" setup>

usePageAttrsManager()

const modalsStore = useModalsStore()

if (import.meta.server) {
    performanceCheckpoint(useRequestEvent()!, 'page-render')
}

</script>
