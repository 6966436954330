import { ApiService, type ApiServiceFetchOptions } from '@composable-api/service/api.service'
import type { ApiModel } from '@composable-api/api.model'
import { CartModel } from '../models/cart.model'
import { CartDiscountModel } from '../models/cart-discount.model'

class CartDiscountsApiService<M extends ApiModel> extends ApiService<M> {

    post(code: string, options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'POST',
            body: {
                [CartDiscountModel.ATTR_CODE]: code,
            },
        })
    }

    delete(options?: ApiServiceFetchOptions<M>) {
        return this
            .fetch({
                ...options,
                method: 'DELETE',
            })
    }

}

export function getCartDiscountsApiService(options: {
    cartId: string
}) {
    return new CartDiscountsApiService(
        {
            endpoint: `/carts/${options.cartId}/discounts`,
        },
        CartModel
    )
}
