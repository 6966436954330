import { useNuxtApp } from '#app'

/**
 * Localize a string
 * @todo install i18n to this module & move the translations to the module
 * @param key the key to the translation
 */
export function useT(key: string) {
    const { $i18n }  = useNuxtApp()
    // @ts-ignore
    return $i18n?.t?.(`_core_simploshop.${key}`) || key
}
