import {
    ApiReactiveService,
    type ApiServiceUseFetchOptions
} from '@composable-api/service/api.reactive-service'
import type { ApiModel } from '@composable-api/api.model'
import { OrderModel } from '../models/order.model'
import type { ApiServiceFetchOptions } from '@composable-api/service/api.service'
import { ApiService } from '@composable-api/service/api.service'

class OrdersApiReactiveService<M extends ApiModel> extends ApiReactiveService<M> {
    useGet<T extends boolean>(options?: ApiServiceUseFetchOptions<M, T>) {
        return this.useFetch({
            ...options,
            method: 'GET',
        })
    }
}

export function useOrdersApiService() {
    return new OrdersApiReactiveService(
        {
            endpoint: '/orders',
        },
        OrderModel
    )
}

class OrdersApiService<M extends ApiModel> extends ApiService<M> {
    get(options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'GET',
        })
    }

    post(data: OrderPayload, options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'POST',
            body: data,
        })
    }
}

export function getOrdersApiService() {
    return new OrdersApiService({
        endpoint: '/orders'
    }, OrderModel)
}

interface OrderPayload {
    cart_id: string
    note: string | null
}
