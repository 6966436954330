/**
 * A composable that manages the page scroll lock and double tap zoom.
 * Calling it will initialize the scroll locks and disable double tap to zoom functionality.
 */
export default function () {
    const popupsStore = usePopupsStore()
    const {
        _lock: lock,
        _unlock: unlock,
    } = useBodyScrollLock()

    watch(() => popupsStore.isScrollLocked, (isScrollLocked) => {
        if (isScrollLocked) {
            lock()
        } else {
            unlock()
        }
    })

    function keyEventHandler(e: KeyboardEvent) {
        if (e.key === 'Escape') {
            popupsStore.closeTopmostPopup()
        }
    }
    useEventListener('keydown', keyEventHandler)

    if (import.meta.client) {
        disableDoubleTapZoom()
    }
}
