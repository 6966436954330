import { defineStore } from 'pinia'

export const usePopupsStore = defineStore('popups', () => {
    /*
      An array of callbacks to close each modal.
      Keeps track of all modals that are currently open.
     */
    const openPopups = ref<OpenPopupEntry[]>([])

    // whether the scroll on the body element is locked
    const isScrollLocked = ref<boolean>(false)
    // scrollbar width to use for padding (so that the page doesn't jump when the scroll is locked)
    const _scrollBarWidth = ref<number>(0)

    /**
     * Returns the amount of padding in pixels that should be applied to an element (usually the body element) to
     * prevent the page from jumping when the scroll is locked.
     */
    const paddingWhenScrollLocked = computed(() => isScrollLocked.value ? `${_scrollBarWidth.value}px` : '')

    /**
     * Tries to unlock the scroll on the body.
     * If there are some popups that require locked scroll still open, the scroll will remain locked.
     */
    function tryToUnlockScroll() {
        isScrollLocked.value = openPopups.value.some(popup => popup.lockScroll)
    }

    /**
     * Saves an entry of an open popup. A close callback is required to be able to close the popup later.
     * ___
     * _Default behaviour:_
     * By default, an open popup **locks** the scroll of the page.
     * This can be overridden by setting the `lockScroll` property of the OpenPopupEntry to `false`.
     *
     * @param popup the open popup entry object or a function to close the popup (in case a function is provided,
     * the remaining properties will be set to their default values - `lockScroll: true`)
     */
    function saveOpenPopup(popup: OpenPopupEntry | (() => void)) {
        openPopups.value.push(
            typeof popup === 'function'
                ? {
                    closeCallback: popup,
                    lockScroll: true,
                }
                : {
                    closeCallback: popup.closeCallback,
                    lockScroll: popup.lockScroll ?? true,
                }
        )

        // update the `isLocked` variable
        isScrollLocked.value = openPopups.value.some(popup => popup.lockScroll)
    }

    /**
     * A cleanup function meant to be used when the popup is closed using the `v-model` binding instead
     * of the function to close the topmost popup.
     * In this case, we need to make sure we don't leave the popup in the open popups array.
     *
     * It is safe to call the function even if the popup is not present in the open popups array.
     * @param closeCallback the callback used in the `saveOpenPopup` method to identify the current popup opening
     * @returns whether the popup was cleaned up (i.e. was present in the open popups array)
     */
    function cleanupOpenPopup(closeCallback: () => void): boolean {
        const oldNoOpenPopups = openPopups.value.length
        openPopups.value = openPopups.value.filter(popup => popup.closeCallback !== closeCallback)
        return oldNoOpenPopups > openPopups.value.length
    }

    /**
     * Closes the popup that was opened last. (based on the saved close callback)
     *
     * Needs to be performant, as it is called on every ESC keydown event.
     */
    function closeTopmostPopup() {
        const lastPopup = openPopups.value[openPopups.value.length - 1]
        if (lastPopup) lastPopup.closeCallback()
    }

    return {
        isScrollLocked,
        _scrollBarWidth,
        paddingWhenScrollLocked,
        tryToUnlockScroll,
        saveOpenPopup,
        closeTopmostPopup,
        cleanupOpenPopup,
    }
})

export interface OpenPopupEntry {
    closeCallback: () => void
    lockScroll?: boolean
}
