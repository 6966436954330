import type { RouteLocationNormalized } from 'vue-router'
import { type H3Event, getRequestHeader } from 'h3'
import { getSessionRefreshApiService } from '@core-theme/app/assets/api/services/SessionRefresh.service'

export function doesRouteRequireAuth(route: RouteLocationNormalized) {
    return !!route.meta.auth
}

export function isRouteOnlyForGuests(route: RouteLocationNormalized) {
    return route.meta.auth === false
}

export async function refreshAuthSession(event: H3Event | undefined) {
    const cookieHeader = event ? getRequestHeader(event, 'cookie') : undefined

    const response = await getSessionRefreshApiService()
        .post({
            forwardCookiesFromSSR: true,
            headers: cookieHeader ? {
                cookie: cookieHeader,
            } : undefined,
        })

    const sessionModel = response.getItem()

    if (!event || !sessionModel) return
    // store the new access token in the event context so that it can be used during SSR in the current request
    // this is only necessary if the session expires during rendering (after the initial request - because
    // the session is refreshed before rendering if it is expired)
    event.context.newAuthToken = sessionModel.accessToken ?? undefined
}
