import { useConfig } from '../private/config'
import { type MaybeRefOrGetter, computed, toValue } from 'vue'
import { joinURL } from 'ufo'
import type { H3Event } from 'h3'

/**
 * Check whether the request URL is for the same domain or a subdomain of the API.
 * @param requestUrl the URL of the current request
 */
export function useIsFetchingFromSubdomain(requestUrl: MaybeRefOrGetter<string>) {
    return computed(() => isFetchingFromSubdomain(toValue(requestUrl)))
}

/**
 * Check whether the request URL is for the same domain or a subdomain of the API.
 * @param requestUrl the URL of the current request
 */
export function isFetchingFromSubdomain(requestUrl: string) {
    let result = false

    const currentUrl = useConfig().baseUrl

    try {
        result = currentUrl?.includes(new URL(toValue(requestUrl)).hostname) ?? false
    } catch (e) {
        result = true
    }

    return result
}

/**
 * Get the full URL of the site.
 *
 * If a route is provided, it will be appended to the base URL.
 * The route should be a relative path in the form of a string.
 * (use `useLocalePath` return value for the route)
 * @param route
 */
export function useFullUrl(route?: string): string
export function useFullUrl(event: H3Event, route?: string): string
export function useFullUrl(arg1?: H3Event | string, arg2?: string): string {
    const event = typeof arg1 === 'string' ? undefined : arg1
    const route = typeof arg1 === 'string' ? arg1 : arg2

    const config = useConfig(event)

    if (route) {
        return joinURL(config.baseUrl, route)
    }

    return config.baseUrl as string
}
