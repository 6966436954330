import { ApiReactiveService, type ApiServiceUseFetchOptions } from '@composable-api/service/api.reactive-service'
import { type ApiServiceFetchOptions } from '@composable-api/service/api.service'
import type { ApiModel } from '@composable-api/api.model'
import { CustomerProductListModel } from '../models/customer-product-list.model'

class CustomerProductListsApiReactiveService<M extends ApiModel> extends ApiReactiveService<M> {
    post(data: CustomerProductListsPayload, options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'POST',
            body: data,
        })
    }

    useGet<T extends boolean>(options?: ApiServiceUseFetchOptions<M, T>) {
        return this.useFetch({
            ...options,
            method: 'GET',
        })
    }
}

export default function useCustomerProductListsApiService() {
    return new CustomerProductListsApiReactiveService({
        endpoint: '/customer-product-lists',
    }, CustomerProductListModel)
}

interface CustomerProductListsPayload {
    customer_id: number,
    name: string
    description: string | null,
    type: number,
    position: number | null,
}
