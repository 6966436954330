export interface ProductSetPopup {
    badge?: string
    heading: string
    subheading: string
    link: string
    position: {
        top?: number | string
        bottom?: number | string
        left?: number | string
        right?: number | string
    }
}

export enum CustomerAuthFormType {
    LOGIN,
    REGISTRATION,
    PASSWORD_RESET,
}
